import React, { useState } from 'react';

interface UserProfile {
  name: string;
  discordConnected: boolean;
  googleConnected: boolean;
  emailPassword: {
    email: string;
    password: string;
  } | null;
  guildName: string;
  autoParticipateEvents: string[];
}

const Perfil: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile>({
    name: 'Usuario',
    discordConnected: true,
    googleConnected: false,
    emailPassword: null,
    guildName: '',
    autoParticipateEvents: [],
  });

  const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  const handleConnectToggle = (service: 'discord' | 'google') => {
    setProfile(prev => {
      const updatedProfile = {
        ...prev,
        [service === 'discord' ? 'discordConnected' : 'googleConnected']: !prev[service === 'discord' ? 'discordConnected' : 'googleConnected']
      };
      
      // Ensure at least one service is always connected
      if (!updatedProfile.discordConnected && !updatedProfile.googleConnected && !updatedProfile.emailPassword) {
        updatedProfile[service === 'discord' ? 'googleConnected' : 'discordConnected'] = true;
      }
      
      return updatedProfile;
    });
  };

  const handleEmailPasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;
    
    setProfile(prev => ({
      ...prev,
      emailPassword: { email, password }
    }));
    setShowEmailPasswordForm(false);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, name: e.target.value });
  };

  const handleGuildNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, guildName: e.target.value });
  };

  const handleAutoParticipateChange = (eventName: string) => {
    setProfile(prevProfile => {
      const updatedEvents = prevProfile.autoParticipateEvents.includes(eventName)
        ? prevProfile.autoParticipateEvents.filter(event => event !== eventName)
        : [...prevProfile.autoParticipateEvents, eventName];
      return { ...prevProfile, autoParticipateEvents: updatedEvents };
    });
  };

  const events = [
    "Evento 1", "Evento 2", "Evento 3", "Evento 4",
    "Evento 5", "Evento 6", "Evento 7", "Evento 8",
    "Evento 9", "Evento 10", "Evento 11", "Evento 12"
  ];

  const handleDeleteAccount = () => {
    // Lógica para borrar la cuenta
    console.log('Cuenta borrada');
    setDeleteAccountModal(false);
  };

  return (
    <div className="flex-1 bg-gray-900 text-white min-h-screen">
      <div className="max-w-6xl mx-auto p-8">
        <h1 className="text-2xl font-bold mb-6">Perfil de Usuario</h1>
        
        <div className="bg-gray-800 rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4">Datos de Conexión</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className={`p-4 rounded-lg ${profile.discordConnected ? 'bg-purple-600' : 'bg-purple-800'}`}>
              <div className="flex items-center justify-between mb-2">
                <span className="font-semibold">Discord</span>
                <button
                  onClick={() => handleConnectToggle('discord')}
                  className={`px-3 py-1 rounded text-sm transition-colors duration-200 ${
                    profile.discordConnected
                      ? 'bg-purple-500 hover:bg-purple-400'
                      : 'bg-purple-700 hover:bg-purple-600'
                  } text-white`}
                >
                  {profile.discordConnected ? 'Conectado' : 'Conectar'}
                </button>
              </div>
              <p className="text-xs text-purple-200">
                {profile.discordConnected ? 'Conectado a Discord' : 'No conectado a Discord'}
              </p>
            </div>
            <div className={`p-4 rounded-lg ${profile.googleConnected ? 'bg-red-600' : 'bg-red-800'}`}>
              <div className="flex items-center justify-between mb-2">
                <span className="font-semibold">Google</span>
                <button
                  onClick={() => handleConnectToggle('google')}
                  className={`px-3 py-1 rounded text-sm transition-colors duration-200 ${
                    profile.googleConnected
                      ? 'bg-red-500 hover:bg-red-400'
                      : 'bg-red-700 hover:bg-red-600'
                  } text-white`}
                >
                  {profile.googleConnected ? 'Conectado' : 'Conectar'}
                </button>
              </div>
              <p className="text-xs text-red-200">
                {profile.googleConnected ? 'Conectado a Google' : 'No conectado a Google'}
              </p>
            </div>
            <div className={`p-4 rounded-lg ${profile.emailPassword ? 'bg-green-600' : 'bg-green-800'}`}>
              <div className="flex items-center justify-between mb-2">
                <span className="font-semibold">Correo y Contraseña</span>
                {profile.emailPassword ? (
                  <button
                    onClick={() => setProfile(prev => ({ ...prev, emailPassword: null }))}
                    className="px-3 py-1 rounded text-sm bg-green-500 hover:bg-green-400 text-white transition-colors duration-200"
                  >
                    Desconectar
                  </button>
                ) : (
                  <button
                    onClick={() => setShowEmailPasswordForm(true)}
                    className="px-3 py-1 rounded text-sm bg-green-700 hover:bg-green-600 text-white transition-colors duration-200"
                  >
                    Configurar
                  </button>
                )}
              </div>
              <p className="text-xs text-green-200">
                {profile.emailPassword ? `Conectado como ${profile.emailPassword.email}` : 'No configurado'}
              </p>
            </div>
          </div>
          {showEmailPasswordForm && (
            <form onSubmit={handleEmailPasswordSubmit} className="mt-4 bg-gray-700 p-4 rounded-lg">
              <input
                type="email"
                name="email"
                placeholder="Correo electrónico"
                required
                className="w-full p-2 mb-2 bg-gray-600 rounded"
              />
              <input
                type="password"
                name="password"
                placeholder="Contraseña"
                required
                className="w-full p-2 mb-2 bg-gray-600 rounded"
              />
              <button type="submit" className="px-4 py-2 bg-green-600 hover:bg-green-500 rounded text-white">
                Guardar
              </button>
            </form>
          )}
          <p className="mt-4 text-sm text-gray-400">
            Puedes mantener múltiples métodos de inicio de sesión activos para mayor flexibilidad.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-800 rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Información de Usuario</h2>
            <input 
              type="text"
              placeholder="Nombre de usuario" 
              value={profile.name} 
              onChange={handleNameChange}
              className="w-full p-2 mb-4 border border-gray-700 rounded bg-gray-700 text-white"
            />
            <input 
              type="text"
              placeholder="Nombre en la guild" 
              value={profile.guildName} 
              onChange={handleGuildNameChange}
              className="w-full p-2 border border-gray-700 rounded bg-gray-700 text-white"
            />
          </div>

          <div className="bg-gray-800 rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Participación Automática en Eventos</h2>
            <div className="grid grid-cols-3 sm:grid-cols-4 gap-2">
              {events.map((event) => (
                <button
                  key={event}
                  onClick={() => handleAutoParticipateChange(event)}
                  className={`p-1 text-xs rounded transition-colors duration-200 ${
                    profile.autoParticipateEvents.includes(event)
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  {event}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg shadow-md p-6 mt-8">
          <h2 className="text-xl font-semibold mb-4">Zona de Riesgo</h2>
          <button
            onClick={() => setDeleteAccountModal(true)}
            className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded"
          >
            Borrar mi cuenta
          </button>
        </div>

        {deleteAccountModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 rounded-lg shadow-xl p-6 max-w-md w-full mx-4">
              <h3 className="text-xl font-semibold mb-4 text-white">Confirmar borrado de cuenta</h3>
              <p className="mb-6 text-gray-300">¿Estás seguro de que quieres borrar tu cuenta? Esta acción no se puede deshacer.</p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setDeleteAccountModal(false)}
                  className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded transition-colors duration-200"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleDeleteAccount}
                  className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded transition-colors duration-200"
                >
                  Sí, borrar mi cuenta
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Perfil;