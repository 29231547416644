import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

export interface LoginProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticated: boolean;
}

type ServerName = 'Servidor 1' | 'Servidor 2' | 'Servidor 3';
type GuildList = Record<ServerName, string[]>;

interface WeaponIconProps {
  weapon: string;
  selected: boolean;
}

const zoomAnimation = keyframes`
  0% { transform: scale(1); }
  100% { transform: scale(1.05); }
`;

const WeaponIcon = styled.div<WeaponIconProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: ${props => `url(/icon/${props.weapon})`};
  background-size: cover;
  background-position: center;
  cursor: pointer;
  border: 2px solid ${props => props.selected ? '#4a9eff' : 'transparent'};
  transition: all 0.3s ease;
  &:hover { transform: scale(1.1); }
`;

const WeaponGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
`;

const BackgroundImage = styled.div<{ $image: string; $visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.$image});
  background-size: cover;
  background-position: center;
  opacity: ${props => (props.$visible ? 0.7 : 0)};
  transition: opacity 1s ease-in-out;
  animation: ${zoomAnimation} 15s ease-in-out infinite alternate;
`;

const glowAnimation = keyframes`
  0%, 100% { box-shadow: 0 0 5px #9146ff, 0 0 10px #9146ff, 0 0 15px #9146ff, 0 0 20px #9146ff; }
  50% { box-shadow: 0 0 10px #9146ff, 0 0 20px #9146ff, 0 0 30px #9146ff, 0 0 40px #9146ff; }
`;

const DiscordButton = styled.a`
  background-color: #7289da;
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  margin-bottom: 30px;
  border: 2px solid #9146ff;
  animation: ${glowAnimation} 2s infinite;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
  &:hover {
    background-color: #5e73bc;
    transform: scale(1.05);
  }
`;

const Login: React.FC<LoginProps> = ({ setIsAuthenticated, isAuthenticated }) => {
  const [guildName, setGuildName] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [selectedWeapons, setSelectedWeapons] = useState<string[]>([]);
  const [showNewGuildForm, setShowNewGuildForm] = useState(false);
  const [showJoinGuildForm, setShowJoinGuildForm] = useState(false);
  const [showAuthOptions, setShowAuthOptions] = useState(false);
  const [showRequestSentModal, setShowRequestSentModal] = useState(false);
  const [selectedServer, setSelectedServer] = useState<ServerName | ''>('');
  const [selectedGuild, setSelectedGuild] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  
  const servers: ServerName[] = ['Servidor 1', 'Servidor 2', 'Servidor 3'];
  const guilds: GuildList = {
    'Servidor 1': ['Guild A', 'Guild B'],
    'Servidor 2': ['Guild C', 'Guild D'],
    'Servidor 3': ['Guild E', 'Guild F'],
  };
  const weapons = [
    'bow.png', 'crossbow.png', 'dagger.png', 'greatsword.png', 
    'sns.png', 'staff.png', 'wand.png'
  ];
  const backgroundImages = [
    '/background/Throne_and_Liberty_combat.jpg',
    '/background/Throne_and_Liberty_wallpaper_1.jpg',
    '/background/Throne_and_Liberty_wallpaper_2.jpg',
    '/background/Throne_and_Liberty_wallpaper_3.jpg',
    '/background/Throne_and_Liberty_wallpaper_4.jpg',
    '/background/Throne_and_Liberty_wallpaper_5.jpg',
    '/background/Throne_and_Liberty_wallpaper_6.jpg',
    '/background/Throne_and_Liberty_wallpaper_7.jpg',
    '/background/Throne_and_Liberty_wallpaper_8.jpg',
    '/background/Throne_and_Liberty_wallpaper_9.jpg',
    '/background/Throne_and_Liberty_wallpaper.jpg',
    '/background/Throne_and_Liberty_3.jpg'
  ];

  const checkExistingSession = async () => {
    const accessToken = sessionStorage.getItem('discord_access_token');
    if (accessToken) {
      try {
        const response = await fetch('https://discord.com/api/users/@me', {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        if (response.ok) return await response.json();
        throw new Error('Token inválido');
      } catch (error) {
        console.error('Error checking existing session:', error);
        throw error;
      }
    }
    throw new Error('No hay token');
  };

  const refreshToken = async () => {
    const refreshToken = sessionStorage.getItem('discord_refresh_token');
    if (!refreshToken) throw new Error('No hay token de actualización');

    try {
      const response = await fetch('https://api.ofguild.com/api/refresh-token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });

      if (!response.ok) {
        throw new Error('No se pudo refrescar el token');
      }

      const { access_token, refresh_token } = await response.json();
      sessionStorage.setItem('discord_access_token', access_token);
      sessionStorage.setItem('discord_refresh_token', refresh_token);

      return access_token;
    } catch (error) {
      console.error('Error al refrescar el token:', error);
      throw error;
    }
  };

  const handleDiscordLogin = async () => {
    try {
      await checkExistingSession();
      navigateToDashboard();
    } catch (error) {
      try {
        await refreshToken();
        navigateToDashboard();
      } catch (refreshError) {
        console.error('Error al refrescar el token:', refreshError);
        initiateOAuthFlow();
      }
    }
  };

  const navigateToDashboard = () => {
    setIsAuthenticated(true);
    navigate('/dashboard');
  };

  const initiateOAuthFlow = () => {
    const state = uuidv4();
    sessionStorage.setItem('oauth_state', state);
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=YOUR_CLIENT_ID&redirect_uri=${encodeURIComponent('https://tl.ofguild.com/oauth/callback')}&response_type=code&scope=identify%20email&state=${state}`;
  };

  const handleCreateGuild = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedWeapons.length !== 2) {
      alert('Por favor, selecciona exactamente 2 armas.');
      return;
    }
    setShowAuthOptions(true);
  };

  const handleJoinGuild = (guild: string) => {
    if (selectedWeapons.length !== 2) {
      alert('Por favor, selecciona exactamente 2 armas.');
      return;
    }
    setSelectedGuild(guild);
    setShowRequestSentModal(true);
  };

  const handleAuth = async (provider: 'discord' | 'google') => {
    setLoading(true);
    setError(null);
  
    try {
      const stateId = uuidv4();
      const state = `${stateId}_${Date.now()}`;
      const stateObj = { state, expiry: Date.now() + 600000 }; // 10 minutos de expiración
      
      // Almacenar múltiples estados
      const existingStates = JSON.parse(sessionStorage.getItem('oauth_states') || '{}');
      existingStates[stateId] = stateObj;
      sessionStorage.setItem('oauth_states', JSON.stringify(existingStates));
      
      console.log('Estado generado y almacenado:', state); // Para depuración
  
      const response = await fetch('https://api.ofguild.com/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ provider, state }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        console.log('Redirigiendo a:', data.url); // Para depuración
        window.location.href = data.url;
      } else {
        setError(data.error || 'An error occurred during login');
      }
    } catch (err) {
      console.error('Error en handleAuth:', err);
      setError('An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get('code');
      const receivedState = searchParams.get('state');
  
      console.log('Received code:', code);
      console.log('Received state:', receivedState);
  
      const storedStatesJSON = sessionStorage.getItem('oauth_states');
      if (!storedStatesJSON) {
        console.error('No stored states found');
        setError('No se encontraron estados almacenados. Por favor, intente iniciar sesión nuevamente.');
        return;
      }
  
      const storedStates = JSON.parse(storedStatesJSON);
      const stateId = receivedState?.split('_')[0];
      const storedStateObj = stateId ? storedStates[stateId] : undefined;

      if (!storedStateObj) {
        console.error('No matching stored state found');
        setError('No se encontró un estado coincidente. Por favor, intente iniciar sesión nuevamente.');
        return;
      }
  
      const { state: storedState, expiry } = storedStateObj;
      const isExpired = Date.now() > expiry;
  
      console.log('Stored state:', storedState);
      console.log('Is expired:', isExpired);
  
      if (isExpired) {
        console.error('Stored state has expired');
        setError('La sesión ha expirado. Por favor, intente iniciar sesión nuevamente.');
        if (stateId !== undefined) {
          delete storedStates[stateId];
          sessionStorage.setItem('oauth_states', JSON.stringify(storedStates));
        }
        return;
      }
  
      if (code && receivedState) {
        try {
          if (receivedState !== storedState) {
            console.error('State mismatch. Stored:', storedState, 'Received:', receivedState);
            throw new Error('State mismatch. Possible CSRF attack.');
          }
  
          if (stateId !== undefined) {
            delete storedStates[stateId];
            sessionStorage.setItem('oauth_states', JSON.stringify(storedStates));
          }
  
          const response = await fetch(`https://api.ofguild.com/oauth/callback?code=${code}&state=${receivedState}`);
          if (!response.ok) {
            throw new Error(`Server responded with ${response.status}: ${await response.text()}`);
          }
          const data = await response.json();
          
          console.log('Received data from server:', data);
          
          localStorage.setItem('session', JSON.stringify(data));
          setIsAuthenticated(true);
          navigate('/');
        } catch (error) {
          console.error('Error al procesar el callback de OAuth:', error);
          setError(error instanceof Error ? `Error en la autenticación: ${error.message}` : 'Error desconocido en la autenticación');
        }
      } else if (!code || !receivedState) {
        console.error('Missing code or state in the callback URL');
        console.log('Full URL:', window.location.href);
        setError('Falta el código o el estado en la URL de callback');
      }
    };
  
    if (location.pathname.includes('/oauth/callback')) {
      handleCallback();
    }
  }, [location, navigate, setIsAuthenticated]);

  const handleWeaponSelect = (weapon: string) => {
    setSelectedWeapons(prev => {
      if (prev.includes(weapon)) {
        return prev.filter(w => w !== weapon);
      } else {
        return [...prev, weapon].slice(-2);
      }
    });
  };

  const WeaponSelector = () => (
    <div className="mb-4">
      <p className="mb-2 text-white">Selecciona tus 2 armas principales:</p>
      <WeaponGrid>
        {weapons.map(weapon => (
          <WeaponIcon
            key={weapon}
            weapon={weapon}
            selected={selectedWeapons.includes(weapon)}
            onClick={() => handleWeaponSelect(weapon)}
          />
        ))}
      </WeaponGrid>
    </div>
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch('https://api.ofguild.com/auth/status', {
          credentials: 'include',
        });
        const data = await response.json();
        if (data.isAuthenticated) {
          setIsAuthenticated(true);
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
      }
    };

    checkAuthStatus();
  }, [setIsAuthenticated, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <>
      <BackgroundContainer>
        {backgroundImages.map((image, index) => (
          <BackgroundImage
            key={image}
            $image={image}
            $visible={index === currentImageIndex}
          />
        ))}
      </BackgroundContainer>
      <div className="flex flex-col min-h-screen text-white relative">
        <div className="relative z-10 flex-grow flex items-center justify-center">
          <div className="w-full max-w-4xl p-8 flex flex-col items-center">
            <h1 className="text-3xl font-bold mb-8 text-center text-yellow-500 [text-shadow:_-1px_-1px_0_#000,_1px_-1px_0_#000,_-1px_1px_0_#000,_1px_1px_0_#000]">
              Bienvenido a TL
            </h1>
            <DiscordButton onClick={handleDiscordLogin}>
              Únete con Discord
            </DiscordButton>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              <div 
                className="bg-gray-800 p-8 rounded-lg shadow-lg cursor-pointer hover:bg-gray-700 transition"
                onClick={() => setShowNewGuildForm(true)}
              >
                <h2 className="text-2xl font-bold mb-4">Nueva Guild</h2>
                <p>Crea tu propia guild y comienza tu aventura.</p>
              </div>
              <div 
                className="bg-gray-800 p-8 rounded-lg shadow-lg cursor-pointer hover:bg-gray-700 transition"
                onClick={() => setShowJoinGuildForm(true)}
              >
                <h2 className="text-2xl font-bold mb-4">Unirse a una Guild</h2>
                <p>Busca y únete a una guild existente.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center mt-auto mb-8">
          <p className="text-xl mb-4 text-yellow-500 [text-shadow:_-1px_-1px_0_#000,_1px_-1px_0_#000,_-1px_1px_0_#000,_1px_1px_0_#000]">
            ¿Ya tienes una cuenta? Inicia sesión directamente:
          </p>
          <div className="flex space-x-4">
            <button
              onClick={() => handleAuth('discord')}
              className="bg-indigo-600 text-white px-6 py-2 rounded hover:bg-indigo-700 transition"
              disabled={loading}
            >
              {loading ? 'Cargando...' : 'Entrar con Discord'}
            </button>
            <button
              onClick={() => handleAuth('google')}
              className="bg-red-600 text-white px-6 py-2 rounded hover:bg-red-700 transition"
              disabled={loading}
            >
              {loading ? 'Cargando...' : 'Entrar con Google'}
            </button>
          </div>
        </div>
      </div>

      {showNewGuildForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-white">Crear Nueva Guild</h2>
            <form onSubmit={handleCreateGuild}>
              <input
                type="text"
                placeholder="Tu nombre"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                className="w-full p-2 mb-4 rounded bg-gray-700 text-white placeholder-gray-400"
                required
              />
              <WeaponSelector />
              <input
                type="text"
                placeholder="Nombre de la Guild"
                value={guildName}
                onChange={(e) => setGuildName(e.target.value)}
                className="w-full p-2 mb-4 rounded bg-gray-700 text-white placeholder-gray-400"
                required
              />
              <button type="submit" className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
                Crear Guild
              </button>
            </form>
            <button 
              onClick={() => setShowNewGuildForm(false)}
              className="mt-4 w-full bg-gray-600 text-white p-2 rounded hover:bg-gray-700"
            >
              Cancelar
            </button>
          </div>
        </div>
      )}

      {showJoinGuildForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-white">Unirse a una Guild</h2>
            <input
              type="text"
              placeholder="Tu nombre"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
              className="w-full p-2 mb-4 rounded bg-gray-700 text-white placeholder-gray-400"
              required
            />
            <WeaponSelector />
            <select
              value={selectedServer}
              onChange={(e) => setSelectedServer(e.target.value as ServerName)}
              className="w-full p-2 mb-4 rounded bg-gray-700 text-white"
            >
              <option value="" className="text-white bg-gray-700">Selecciona un servidor</option>
              {servers.map(server => (
                <option key={server} value={server} className="text-white bg-gray-700">{server}</option>
              ))}
            </select>
            {selectedServer && (
              <div className="grid grid-cols-2 gap-4">
                {guilds[selectedServer].map((guild: string) => (
                  <button
                    key={guild}
                    onClick={() => handleJoinGuild(guild)}
                    className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
                  >
                    {guild}
                  </button>
                ))}
              </div>
            )}
            <button 
              onClick={() => setShowJoinGuildForm(false)}
              className="mt-4 w-full bg-gray-600 text-white p-2 rounded hover:bg-gray-700"
            >
              Cancelar
            </button>
          </div>
        </div>
      )}

      {showAuthOptions && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-white">Elige cómo quieres autenticarte</h2>
            <div className="flex flex-col space-y-4">
              <button
                onClick={() => handleAuth('discord')}
                className="w-full bg-indigo-600 text-white p-2 rounded hover:bg-indigo-700"
                disabled={loading}
              >
                {loading ? 'Cargando...' : 'Entrar con Discord'}
              </button>
              <button
                onClick={() => handleAuth('google')}
                className="w-full bg-red-600 text-white p-2 rounded hover:bg-red-700"
                disabled={loading}
              >
                {loading ? 'Cargando...' : 'Entrar con Google'}
              </button>
            </div>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            <button 
              onClick={() => setShowAuthOptions(false)}
              className="mt-4 w-full bg-gray-600 text-white p-2 rounded hover:bg-gray-700"
              disabled={loading}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}

      {showRequestSentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-white">Unirse a Guild</h2>
            <p className="mb-4 text-white">
              Una vez que te autentiques, se enviará tu solicitud para unirte a la guild "{selectedGuild}".
              Podrás revisar los detalles de tu solicitud en la vista "Mi Guild" después de iniciar sesión.
            </p>
            <p className="mb-4 text-white">
              Por favor, elige cómo quieres autenticarte para continuar:
            </p>
            <div className="flex flex-col space-y-4">
              <button
                onClick={() => handleAuth('discord')}
                className="w-full bg-indigo-600 text-white p-2 rounded hover:bg-indigo-700"
                disabled={loading}
              >
                {loading ? 'Cargando...' : 'Entrar con Discord'}
              </button>
              <button
                onClick={() => handleAuth('google')}
                className="w-full bg-red-600 text-white p-2 rounded hover:bg-red-700"
                disabled={loading}
              >
                {loading ? 'Cargando...' : 'Entrar con Google'}
              </button>
            </div>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            <button 
              onClick={() => setShowRequestSentModal(false)}
              className="mt-4 w-full bg-gray-600 text-white p-2 rounded hover:bg-gray-700"
              disabled={loading}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;