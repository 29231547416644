import React from 'react';

interface GuildMember {
  id: string;
  name: string;
  class: string;
  role: 'DPS' | 'Tank' | 'Healer';
}

interface Party {
  id: string;
  name: string;
  members: (GuildMember | null)[];
  leaderId: string | null;
}

interface PartiesProps {
  parties: Party[];
  selectedPartyId: string | null;
  setSelectedPartyId: (id: string | null) => void;
  createNewParty: () => void;
  calculateSynergy: (party: Party) => number;
  deleteParty: (partyId: string) => void;
  startEditingPartyName: (partyId: string, currentName: string) => void;
  updatePartyName: (partyId: string) => void;
  editingPartyId: string | null;
  editingPartyName: string;
  setEditingPartyName: (name: string) => void;
  togglePartyLeader: (partyId: string, memberId: string) => void;
  toggleMemberInParty: (member: GuildMember, partyId: string) => void;
}

const Parties: React.FC<PartiesProps> = ({
  parties,
  selectedPartyId,
  setSelectedPartyId,
  createNewParty,
  calculateSynergy,
  deleteParty,
  startEditingPartyName,
  updatePartyName,
  editingPartyId,
  editingPartyName,
  setEditingPartyName,
  togglePartyLeader,
  toggleMemberInParty
}) => {
  const getMemberStyle = (role: string) => {
    let borderColor = '';
    let textColor = '';
    switch (role) {
      case 'Tank':
        borderColor = 'border-blue-500';
        textColor = 'text-blue-400';
        break;
      case 'Healer':
        borderColor = 'border-green-500';
        textColor = 'text-green-400';
        break;
      case 'DPS':
        borderColor = 'border-red-500';
        textColor = 'text-red-400';
        break;
      default:
        borderColor = 'border-gray-500';
        textColor = 'text-gray-400';
    }
    return `bg-gray-800 ${borderColor} border-2 ${textColor}`;
  };

  return (
    <div className="h-full p-2 flex flex-col overflow-hidden">
      <div className="flex-1 overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 p-1">
          {parties.map(party => (
            <div className="p-1" key={party.id}>
              <div
                className={`bg-gray-800 rounded-lg shadow-xl overflow-hidden ${
                  selectedPartyId === party.id ? 'ring-2 ring-blue-500' : ''
                } transition-all duration-200 ease-in-out hover:bg-gray-750`}
                onClick={() => setSelectedPartyId(party.id)}
              >
                <div className="p-2">
                  <div className="flex justify-between items-center mb-1">
                    {editingPartyId === party.id ? (
                      <input
                        type="text"
                        value={editingPartyName}
                        onChange={(e) => setEditingPartyName(e.target.value)}
                        onBlur={() => updatePartyName(party.id)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            updatePartyName(party.id);
                          }
                        }}
                        autoFocus
                        className="bg-gray-700 text-white text-sm font-semibold p-1 rounded"
                      />
                    ) : (
                      <h3 
                        className="text-sm font-semibold cursor-pointer" 
                        onClick={() => startEditingPartyName(party.id, party.name)}
                      >
                        {party.name}
                      </h3>
                    )}
                    <div className="flex items-center space-x-2">
                      <span className="text-xs">({party.members.filter(m => m !== null).length}/6)</span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteParty(party.id);
                        }}
                        className="text-gray-400 hover:text-red-400 text-xs transition-colors duration-200"
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="flex justify-between items-center text-xs mb-1">
                      <span>Synergy:</span>
                      <span>{calculateSynergy(party)}%</span>
                    </div>
                    <div className="w-full bg-gray-700 rounded-full h-1">
                      <div 
                        className={`h-full rounded-full ${
                          calculateSynergy(party) === 100 ? 'bg-green-500' :
                          calculateSynergy(party) === 66 ? 'bg-yellow-500' : 'bg-red-500'
                        }`}
                        style={{ width: `${calculateSynergy(party)}%` }}
                      ></div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-1 p-1">
                    {Array(6).fill(null).map((_, index) => {
                      const member = party.members[index];
                      return (
                        <div
                          key={member ? member.id : `empty-${index}`}
                          className={`${member ? getMemberStyle(member.role) : 'bg-gray-700 border-2 border-gray-600'} 
                            p-2 rounded text-xs relative group transition-all duration-200`}
                        >
                          {member ? (
                            <>
                              <div className="flex items-center space-x-1">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    togglePartyLeader(party.id, member.id);
                                  }}
                                  className="text-gray-400 hover:text-yellow-400 transition-colors duration-200"
                                >
                                  {party.leaderId === member.id ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-yellow-400">
                                      <path d="M12 1.5l3 5.5 6 1-4.5 4.5 1 6-5.5-3-5.5 3 1-6L3 8l6-1 3-5.5z" />
                                    </svg>
                                  ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 1.5l3 5.5 6 1-4.5 4.5 1 6-5.5-3-5.5 3 1-6L3 8l6-1 3-5.5z" />
                                    </svg>
                                  )}
                                </button>
                                <span className="font-semibold truncate text-white">{member.name}</span>
                              </div>
                              <div className="flex justify-between items-center mt-1">
                                <span className={`text-xs ${getMemberStyle(member.role).split(' ').find(c => c.startsWith('text-'))}`}>{member.role}</span>
                                <span 
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleMemberInParty(member, party.id);
                                  }}
                                  className="text-gray-400 hover:text-red-400 text-xs cursor-pointer transition-colors duration-200 opacity-0 group-hover:opacity-100"
                                >
                                  Quitar
                                </span>
                              </div>
                            </>
                          ) : (
                            <div className="h-full"></div> // Slot vacío sin texto
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Parties;