import React, { useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Build, BuildsData, initialTestBuildsData } from '../data/buildsData';
import BuildCard from './buildlist/BuildCard';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const BuildListContainer = styled.div`
  width: 100%;
`;

const BuildList: React.FC = () => {
  const [builds, setBuilds] = useState<BuildsData>(initialTestBuildsData);

  const handleUpdateBuild = (updatedBuild: Build) => {
    setBuilds(prevBuilds => {
      const newBuilds = { ...prevBuilds };
      for (const category of ['tank', 'healer', 'dps'] as const) {
        const index = newBuilds[category].findIndex(b => b.id === updatedBuild.id);
        if (index !== -1) {
          newBuilds[category][index] = updatedBuild;
          return newBuilds;
        }
      }
      return newBuilds;
    });
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    setBuilds(prevBuilds => {
      const newBuilds = JSON.parse(JSON.stringify(prevBuilds)) as BuildsData;
      const sourceBuildList = newBuilds[source.droppableId as keyof BuildsData];
      const destBuildList = newBuilds[destination.droppableId as keyof BuildsData];

      const [removed] = sourceBuildList.splice(source.index, 1);
      destBuildList.splice(destination.index, 0, removed);

      return newBuilds;
    });
  };

  const renderBuilds = (buildList: Build[], droppableId: string) => {
    return (
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8"
          >
            {buildList.map((build, index) => (
              <Draggable key={build.id} draggableId={build.id.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <BuildCard
                      build={build}
                      onUpdate={handleUpdateBuild}
                      disableClick={true}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <LayoutContainer className="p-2 sm:p-4 bg-gray-900 text-white min-h-screen">
        <BuildListContainer>
          <h1 className="text-xl sm:text-2xl font-bold mb-4">Builds</h1>
          <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
            <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Tank Builds</h2>
            {renderBuilds(builds.tank, 'tank')}
          </div>
          <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
            <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Healer Builds</h2>
            {renderBuilds(builds.healer, 'healer')}
          </div>
          <div className="mb-6 sm:mb-8 bg-gray-800 p-2 sm:p-4 rounded-lg">
            <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">DPS Builds</h2>
            {renderBuilds(builds.dps, 'dps')}
          </div>
        </BuildListContainer>
      </LayoutContainer>
    </DragDropContext>
  );
};

export default BuildList;