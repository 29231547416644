import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export interface DKPSettingsProps {
  guildName: string;
  setGuildName: (value: string) => void;
  guildSlug: string;
  setGuildSlug: (value: string) => void;
  guildImage: string;
  setGuildImage: (value: string) => void;
  enableDKP: boolean;
  setEnableDKP: (value: boolean) => void;
  enableScreenshotUpload: boolean;
  setEnableScreenshotUpload: (value: boolean) => void;
  enableDKPDecay: boolean;
  setEnableDKPDecay: (value: boolean) => void;
  activityDKP: {
    [key: string]: number;
  };
  setActivityDKP: (value: { [key: string]: number }) => void;
}

const Switch = styled.label`
  ${tw`relative inline-block w-14 h-7`}
  
  input {
    ${tw`opacity-0 w-0 h-0`}
    &:checked + span {
      ${tw`bg-blue-600`}
    }
    &:checked + span:before {
      ${tw`transform translate-x-7`}
    }
  }
  
  span {
    ${tw`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-400 transition-all duration-300 rounded-full`}
    &:before {
      content: "";
      ${tw`absolute h-5 w-5 left-1 bottom-1 bg-white transition-all duration-300 rounded-full`}
    }
  }
`;

const activities = [
  'World Event', 'World Dungeon', 'World Boss', 'Arch Boss',
  'Boonstone', 'Riftstone', 'War', 'Siege', 'Guild Contract', 'Raid'
];

const DKP: React.FC<DKPSettingsProps> = ({
  enableDKP,
  setEnableDKP,
  enableScreenshotUpload,
  setEnableScreenshotUpload,
  enableDKPDecay,
  setEnableDKPDecay,
  activityDKP,
  setActivityDKP,
}) => {
  const handleActivityDKPChange = (activity: string, value: string) => {
    const numValue = value === '' ? 0 : parseInt(value, 10);
    setActivityDKP({ ...activityDKP, [activity]: numValue });
  };

  return (
    <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-white">DKP Settings</h2>
      <form className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex items-center justify-between bg-gray-700 p-3 rounded">
            <span className="text-sm text-gray-300">DKP System</span>
            <Switch>
              <input
                type="checkbox"
                checked={enableDKP}
                onChange={(e) => setEnableDKP(e.target.checked)}
              />
              <span></span>
            </Switch>
          </div>
          <div className="flex items-center justify-between bg-gray-700 p-3 rounded">
            <span className="text-sm text-gray-300">Screenshot Upload</span>
            <Switch>
              <input
                type="checkbox"
                checked={enableScreenshotUpload}
                onChange={(e) => setEnableScreenshotUpload(e.target.checked)}
              />
              <span></span>
            </Switch>
          </div>
          <div className="flex items-center justify-between bg-gray-700 p-3 rounded">
            <span className="text-sm text-gray-300">DKP Decay</span>
            <Switch>
              <input
                type="checkbox"
                checked={enableDKPDecay}
                onChange={(e) => setEnableDKPDecay(e.target.checked)}
              />
              <span></span>
            </Switch>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-3 text-white">Activities DKP</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {activities.map((activity) => (
              <div key={activity} className="flex items-center justify-between bg-gray-700 p-3 rounded">
                <span className="text-sm text-gray-300">{activity}</span>
                <input
                  type="number"
                  value={activityDKP[activity] || ''}
                  onChange={(e) => handleActivityDKPChange(activity, e.target.value)}
                  onBlur={(e) => {
                    if (e.target.value === '') {
                      handleActivityDKPChange(activity, '0');
                    }
                  }}
                  className="w-16 p-1 rounded bg-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex space-x-2">
          <button
            type="submit"
            className="flex-1 p-2 rounded bg-blue-600 text-white font-bold hover:bg-blue-700"
          >
            Save Changes
          </button>
          <button
            type="button"
            className="flex-1 p-2 rounded bg-gray-600 text-white font-bold hover:bg-gray-700"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default DKP;
