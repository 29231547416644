import React, { useState, useEffect, useRef, useMemo, useReducer } from 'react';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';

// Importa las imágenes de los jugadores
const playerImages = [
  '/icon/bow_player.png',
  '/icon/staff_player.png',
  '/icon/longsword_player.png',
  '/icon/sns_player.png',
  // Añade aquí más rutas de imágenes si tienes más
];

const Ruleta: React.FC = () => {
  const [participantes, setParticipantes] = useState<Array<{ nombre: string, imagen: string, deshabilitado: boolean }>>([]);
  const [guildMembers, setGuildMembers] = useState<Array<{ nombre: string, imagen: string, deshabilitado: boolean }>>([]);
  const [todosSeleccionados, setTodosSeleccionados] = useState(false);
  const [indiceActual, setIndiceActual] = useState<number | null>(null);
  const [ganador, setGanador] = useState<string | null>(null);
  const [ganadorIndex, setGanadorIndex] = useState<number | null>(null);
  const [sorteando, setSorteando] = useState(false);
  const [progreso, setProgreso] = useState(0);
  const [historico, setHistorico] = useState<Array<{ nombre: string, tipo: 'Ganador' | 'Al Agua' }>>([]);
  const [tipoSorteo, setTipoSorteo] = useState<'ganador' | 'alAgua'>('ganador');
  const [participanteActual, setParticipanteActual] = useState<string | null>(null);
  const [swapDirection, setSwapDirection] = useState<1 | -1>(1);
  const workerRef = useRef<Worker | null>(null);
  const totalDuration = 3000; // 3 seconds total
  const frameDuration = 200; // ms per frame (reduced from 300 to 200)
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [resaltados, setResaltados] = useState<number[]>([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const participantesActuales = useMemo(() => {
    return todosSeleccionados 
      ? guildMembers.filter(m => !m.deshabilitado)
      : participantes.filter(p => !p.deshabilitado);
  }, [todosSeleccionados, guildMembers, participantes]);

  useEffect(() => {
    workerRef.current = new Worker(new URL('../sorteoWorker.ts', import.meta.url));
    workerRef.current.onmessage = (event) => {
      const { tipo, indice, nombre, progreso, tipoSorteo } = event.data;
      if (tipo === 'actualizacion') {
        setIndiceActual(indice);
        setParticipanteActual(nombre);
        setSwapDirection(prev => (prev * -1) as 1 | -1);
        setProgreso(progreso);
        
        // Actualizar los índices resaltados
        setResaltados(prevResaltados => {
          const nuevosResaltados = [...prevResaltados, indice];
          if (nuevosResaltados.length > 5) {
            nuevosResaltados.shift();
          }
          return nuevosResaltados;
        });
      } else if (tipo === 'finalizado') {
        finalizarSorteo(indice, nombre, tipoSorteo);
        setResaltados([]);
      }
    };

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, []);

  const sortear = () => {
    if (participantesActuales.length < 2) return;
    
    // Usar un estado local para asegurar el valor más reciente
    const tipoSorteoActual = tipoSorteo;
    console.log('Iniciando sorteo. Tipo actual:', tipoSorteoActual);
    
    setSorteando(true);
    setGanador(null);
    setGanadorIndex(null);
    setProgreso(100);

    if (workerRef.current) {
      workerRef.current.postMessage({
        participantes: participantesActuales,
        totalDuration,
        frameDuration,
        tipoSorteo: tipoSorteoActual
      });
    }
  };

  const finalizarSorteo = (indiceGanador: number, participanteSeleccionado: string, tipoSorteoFinal: 'ganador' | 'alAgua') => {
    console.log('Finalizando sorteo:', { tipoSorteoFinal, participanteSeleccionado });
    setSorteando(false);
    setGanadorIndex(indiceGanador);
    const nuevoResultado = tipoSorteoFinal === 'ganador' 
      ? { nombre: participanteSeleccionado, tipo: 'Ganador' as const } 
      : { nombre: participanteSeleccionado, tipo: 'Al Agua' as const };
    
    setHistorico(prevHistorico => {
      const nuevoHistorico = [...prevHistorico, nuevoResultado];
      console.log('Nuevo histórico:', nuevoHistorico);
      return nuevoHistorico;
    });

    // Forzar actualización
    forceUpdate();

    setGanador(tipoSorteoFinal === 'ganador' ? participanteSeleccionado : `Al Agua: ${participanteSeleccionado}`);
    setIndiceActual(null);
    setParticipanteActual(null);
  };

  useEffect(() => {
    // Generar 70 miembros de prueba para la guild
    const membersTest = Array.from({ length: 70 }, (_, i) => ({
      nombre: `Miembro${i + 1}`,
      imagen: getRandomPlayerImage(),
      deshabilitado: false
    }));
    setGuildMembers(membersTest);
  }, []);

  // Función para obtener una imagen aleatoria
  const getRandomPlayerImage = () => {
    return playerImages[Math.floor(Math.random() * playerImages.length)];
  };

  const agregarGuildMember = (member: { nombre: string, imagen: string, deshabilitado: boolean }) => {
    if (!todosSeleccionados && !member.deshabilitado) {
      setParticipantes(prevParticipantes => {
        const participanteExistente = prevParticipantes.find(p => p.nombre === member.nombre);
        if (participanteExistente) {
          // Si el participante ya existe, lo quitamos de la lista
          return prevParticipantes.filter(p => p.nombre !== member.nombre);
        } else {
          // Si el participante no existe, lo agregamos a la lista
          return [...prevParticipantes, member];
        }
      });
    }
  };

  const seleccionarTodosGuildMembers = () => {
    setTodosSeleccionados(prev => !prev);
    setParticipantes([]);
  };

  const eliminarGanador = () => {
    if (ganador) {
      const nombreGanador = ganador.startsWith('Al Agua:') ? ganador.slice(13).trim() : ganador;
      const actualizarMiembros = (miembros: typeof guildMembers) =>
        miembros.map(m => m.nombre === nombreGanador ? { ...m, deshabilitado: true } : m);
      
      setGuildMembers(actualizarMiembros);
      setParticipantes(actualizarMiembros);
      setGanador(null);
      setGanadorIndex(null);
    }
  };

  // Renderizar solo los participantes visibles
  const renderParticipantes = () => {
    if (todosSeleccionados) {
      return <p>Todos los miembros seleccionados ({participantesActuales.length})</p>;
    }
    return (
      <div className={`${isMobile ? 'grid grid-cols-3 sm:grid-cols-4' : 'grid grid-cols-5 sm:grid-cols-7 md:grid-cols-10'} gap-2 mb-4`}>
        {participantesActuales.map((participante, index) => (
          <div 
            key={index}
            className={`aspect-w-1 aspect-h-1 rounded overflow-hidden relative flex items-center justify-center text-center p-1 ${
              indiceActual === index ? 'bg-green-500 text-white' : 
              ganadorIndex === index ? 'bg-yellow-500 text-black' : 'bg-gray-700'
            } transition-all duration-100`}
          >
            <span className="text-xs font-bold leading-tight">
              {participante.nombre}
            </span>
          </div>
        ))}
      </div>
    );
  };

  // Modificar la función de renderizado de la grilla de miembros
  const renderGuildMembers = () => {
    return (
      <div className={`${isMobile ? "overflow-x-auto" : "grid grid-cols-10 gap-2"}`}>
        <div className={`${isMobile ? "inline-flex gap-2 pb-2" : "contents"}`}>
          {guildMembers.map((member, index) => (
            <div 
              key={index} 
              className={`
                ${isMobile ? 'w-20 h-20 flex-shrink-0' : 'aspect-w-1 aspect-h-1'}
                rounded overflow-hidden relative cursor-pointer flex items-center justify-center text-center p-1
                ${member.deshabilitado ? 'bg-purple-500 cursor-not-allowed' :
                  todosSeleccionados ? 
                    (indiceActual === index ? 'bg-green-500 text-white' : 
                     ganadorIndex === index ? 'bg-yellow-500 text-black' : 
                     resaltados.includes(index) ? `bg-blue-${500 - resaltados.indexOf(index) * 100} text-white` :
                     'bg-gray-700') :
                    (participantes.some(p => p.nombre === member.nombre) ? 'bg-green-500 text-white' : 'bg-gray-700')
                } transition-all duration-100`}
              onClick={() => !todosSeleccionados && !member.deshabilitado && agregarGuildMember(member)}
            >
              <span className="text-xs font-bold leading-tight">
                {member.nombre}
              </span>
              
            </div>
          ))}
        </div>
      </div>
    );
  };

  const HistoricoItem: React.FC<{ entry: { nombre: string, tipo: 'Ganador' | 'Al Agua' }, index: number }> = ({ entry, index }) => {
    console.log('Renderizando item histórico:', entry);
    return (
      <div 
        className={`p-4 rounded shadow ${
          entry.tipo === 'Ganador' 
            ? 'bg-blue-700 border-2 border-blue-500' 
            : 'bg-purple-700 border-2 border-purple-500'
        }`}
      >
        <p className="font-bold">Sorteo {index + 1}</p>
        <p className={entry.tipo === 'Ganador' ? 'text-blue-300' : 'text-purple-300'}>
          {entry.tipo}: {entry.nombre}
        </p>
      </div>
    );
  };

  // Agregar este console.log para depuración
  console.log("Participantes actuales:", participantes);

  useEffect(() => {
    console.log('Histórico actualizado:', historico);
  }, [historico]);

  useEffect(() => {
    console.log('Tipo de sorteo actual:', tipoSorteo);
  }, [tipoSorteo]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-900 text-white">
      <div className="w-full max-w-7xl">
        {/* Grilla de miembros de la Guild */}
        {(!isMobile || (isMobile && !todosSeleccionados) || (isMobile && todosSeleccionados && !sorteando)) && (
          <div className="w-full bg-gray-800 p-4 rounded-lg mb-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Miembros de la Guild</h2>
              <button
                onClick={seleccionarTodosGuildMembers}
                className={`px-4 py-2 text-white rounded focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                  todosSeleccionados ? 'bg-red-500 hover:bg-red-600 focus:ring-red-500' : 'bg-green-500 hover:bg-green-600 focus:ring-green-500'
                }`}
              >
                {todosSeleccionados ? 'Deseleccionar Todos' : 'Seleccionar Todos los Miembros'}
              </button>
            </div>
            {renderGuildMembers()}
          </div>
        )}

        {/* Ruleta y participantes */}
        <div className="w-full bg-gray-800 p-4 rounded-lg mt-4">
          <h2 className="text-2xl font-bold mb-4">
            {todosSeleccionados ? 'Todos los miembros seleccionados' : `Participantes (${participantesActuales.length})`}
          </h2>
          {!sorteando && renderParticipantes()}
          <div className="flex justify-center items-center h-40 mb-4 overflow-hidden">
            <AnimatePresence mode="wait">
              {(sorteando || ganador) && (
                <motion.div 
                  key={participanteActual || ganador}
                  initial={{ opacity: 0, scale: 0.9, x: swapDirection * 80 }}
                  animate={{ opacity: 1, scale: 1, x: 0 }}
                  exit={{ opacity: 0, scale: 0.9, x: -swapDirection * 80 }}
                  transition={{ duration: 0.15, ease: "easeInOut" }}
                  className={`w-40 h-40 rounded-lg flex items-center justify-center text-center p-4 ${
                    ganador ? 'bg-yellow-500 text-black' : 'bg-green-500 text-white'
                  }`}
                >
                  <span className="text-2xl font-bold leading-tight">
                    {ganador ? ganador : participanteActual}
                  </span>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          
          <div className={`flex flex-col items-center bg-gray-800 text-white p-4 rounded-lg shadow-lg ${
            tipoSorteo === 'ganador' ? 'border-blue-500 border-2' : 'border-purple-500 border-2'
          }`}>
            <div className="w-full bg-gray-300 rounded-full h-4 mb-4">
              <div className="bg-blue-500 h-4 rounded-full" style={{ width: `${progreso}%` }}></div>
            </div>
            <div className="flex justify-between w-full mb-4">
              <button
                onClick={() => {
                  setTipoSorteo('ganador');
                  console.log('Tipo de sorteo cambiado a: ganador');
                }}
                className={`px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                  tipoSorteo === 'ganador' 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-gray-600 text-gray-300 hover:bg-gray-500'
                }`}
              >
                Sortear Ganador
              </button>
              <button
                onClick={() => {
                  setTipoSorteo('alAgua');
                  console.log('Tipo de sorteo cambiado a: alAgua');
                }}
                className={`px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                  tipoSorteo === 'alAgua' 
                    ? 'bg-purple-500 text-white' 
                    : 'bg-gray-600 text-gray-300 hover:bg-gray-500'
                }`}
              >
                Sortear Al Agua
              </button>
            </div>
            <button
              onClick={sortear}
              disabled={sorteando || participantesActuales.length < 2}
              className={`px-4 py-2 text-white rounded hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-opacity-50 w-full disabled:opacity-50 disabled:cursor-not-allowed ${
                tipoSorteo === 'ganador' ? 'bg-blue-500 focus:ring-blue-500' : 'bg-purple-500 focus:ring-purple-500'
              }`}
            >
              {sorteando ? 'Sorteando...' : `Sortear ${tipoSorteo === 'ganador' ? 'Ganador' : 'Al Agua'}`}
            </button>
            {ganador && (
              <button
                onClick={eliminarGanador}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 mt-2 w-full"
              >
                {ganador.startsWith('Al Agua') ? 'Eliminar Descartado' : 'Eliminar Ganador'}
              </button>
            )}
          </div>
        </div>

        {/* Histórico de Sorteos */}
        <div className="w-full bg-gray-800 text-white p-4 rounded-lg shadow-lg mt-4">
          <h2 className="text-2xl font-bold mb-4">Histórico de Sorteos</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {historico.map((entry, index) => (
              <HistoricoItem key={index} entry={entry} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ruleta;