import React, { useState } from 'react';
import styled from 'styled-components';
import General from './settings/General';
import DKP from './settings/DKP';

const TabGrid = styled.div`
  @apply grid grid-cols-9 gap-2 mb-4;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  @apply px-4 py-2 text-sm font-medium rounded-md transition-colors duration-200;
  background-color: ${props => props.isActive ? '#3699FF' : '#1F2937'};
  color: ${props => props.isActive ? 'white' : '#9CA3AF'};

  &:hover {
    background-color: ${props => props.isActive ? '#3699FF' : '#374151'};
  }
`;

const Settings: React.FC = () => {
  const [activeTab, setActiveTab] = useState('General');
  const [guildName, setGuildName] = useState('');
  const [guildSlug, setGuildSlug] = useState('');
  const [guildImage, setGuildImage] = useState('');
  const [region, setRegion] = useState('');
  const [server, setServer] = useState('');
  const [discordLink, setDiscordLink] = useState('');
  const [description, setDescription] = useState('');
  const [enableDKP, setEnableDKP] = useState(false);
  const [enableScreenshotUpload, setEnableScreenshotUpload] = useState(false);
  const [enableDKPDecay, setEnableDKPDecay] = useState(false);
  const [activityDKP, setActivityDKP] = useState<{ [key: string]: number }>({});

  const handleSaveChanges = () => {
    console.log('Guardando cambios...');
  };

  const tabs = ['General', 'Alianza', 'DKP', 'Discord', 'Reclutamiento', 'Permisos', 'Privacidad', 'Roles', 'Billing'];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'General':
        return (
          <General
            guildName={guildName}
            setGuildName={setGuildName}
            guildSlug={guildSlug}
            setGuildSlug={setGuildSlug}
            guildImage={guildImage}
            setGuildImage={setGuildImage}
            region={region}
            setRegion={setRegion}
            server={server}
            setServer={setServer}
            discordLink={discordLink}
            setDiscordLink={setDiscordLink}
            description={description}
            setDescription={setDescription}
          />
        );
      case 'DKP':
        return (
          <DKP
            guildName={guildName}
            setGuildName={setGuildName}
            guildSlug={guildSlug}
            setGuildSlug={setGuildSlug}
            guildImage={guildImage}
            setGuildImage={setGuildImage}
            enableDKP={enableDKP}
            setEnableDKP={setEnableDKP}
            enableScreenshotUpload={enableScreenshotUpload}
            setEnableScreenshotUpload={setEnableScreenshotUpload}
            enableDKPDecay={enableDKPDecay}
            setEnableDKPDecay={setEnableDKPDecay}
            activityDKP={activityDKP}
            setActivityDKP={setActivityDKP}
          />
        );
      default:
        return <p>Content for {activeTab} tab</p>;
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-[1920px] mx-auto">
        <h2 className="text-2xl font-bold mb-4">Settings</h2>
        <TabGrid>
          {tabs.map((tab) => (
            <TabButton
              key={tab}
              isActive={activeTab === tab}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </TabButton>
          ))}
        </TabGrid>

        <div className="bg-gray-800 rounded-lg shadow-xl p-6">
          {renderTabContent()}
        </div>
      </div>
      
      {activeTab === 'General' && (
        <div className="mt-8 max-w-[1920px] mx-auto bg-gray-800 rounded-lg shadow-xl p-6">
          <h2 className="text-2xl font-bold mb-4">Danger zone</h2>
          <p className="text-red-500 mb-4">
            This is a danger zone, please be careful when using the features below as they can cause data loss and are not reversible.
          </p>
          {/* Agregar opciones peligrosas aquí */}
        </div>
      )}
    </div>
  );
};

export default Settings;
