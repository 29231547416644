import React from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';

const localizer = momentLocalizer(moment);

const StyledCalendarWrapper = styled.div`
  .rbc-calendar {
    background-color: #1f2937; /* Fondo oscuro */
  }
  .rbc-month-view {
    border-color: #4b5563;
  }
  .rbc-header {
    background-color: #374151;
    color: #f3f4f6; /* Texto claro para los encabezados */
    padding: 10px 3px;
    font-weight: bold;
  }
  .rbc-date-cell {
    color: #d1d5db; /* Texto gris claro para las fechas */
    padding: 5px;
  }
  .rbc-off-range-bg {
    background-color: #374151;
  }
  .rbc-today {
    background-color: #3b82f6;
  }
  .rbc-event {
    background-color: #2563eb;
    color: #ffffff; /* Texto blanco para los eventos */
    border: none;
    border-radius: 4px;
    padding: 2px 5px;
  }
  .rbc-event-content {
    font-size: 0.8em;
    font-weight: bold;
  }
  .rbc-day-bg + .rbc-day-bg {
    border-color: #4b5563;
  }

  .rbc-btn-group {
    background-color: #374151; /* Fondo oscuro para los botones */
    border-radius: 0.375rem; /* Bordes redondeados */
    overflow: hidden; /* Asegura que los bordes redondeados se apliquen correctamente */
  }

  .rbc-btn-group button {
    color: #f3f4f6; /* Texto claro para los botones */
    background-color: transparent;
    border: 1px solid #4b5563; /* Borde sutil */
    padding: 0.5rem 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4b5563; /* Color de fondo al pasar el mouse */
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #3b82f6; /* Resaltado al enfocar */
    }

    &.rbc-active {
      background-color: #3b82f6; /* Color de fondo para el botón activo */
      border-color: #3b82f6;
    }
  }

  .rbc-toolbar-label {
    color: #f3f4f6; /* Color claro para el texto del mes/año */
    font-weight: bold;
    font-size: 1.25rem;
  }

  .rbc-toolbar button {
    color: #f3f4f6; /* Color claro para los botones de navegación */
  }
`;

const Inicio: React.FC = () => {
  // Datos de ejemplo (reemplazar con datos reales)
  const loginHistory = [
    { date: '2024-03-15', time: '10:30' },
    { date: '2024-03-14', time: '09:45' },
    { date: '2024-03-13', time: '11:15' },
  ];

  const guildAnnouncements = [
    { 
      title: 'Nuevo evento semanal', 
      content: 'Participa en la cacería de Chernobog este sábado.', 
      date: '2024-03-15'
    },
    { 
      title: 'Actualización de reglas', 
      content: 'Revisa las nuevas políticas de distribución de botín.', 
      date: '2024-03-14'
    },
  ];

  const dkpData = [
    { day: 'Lun', points: 50 },
    { day: 'Mar', points: 30 },
    { day: 'Mié', points: 70 },
    { day: 'Jue', points: 40 },
    { day: 'Vie', points: 60 },
    { day: 'Sáb', points: 90 },
    { day: 'Dom', points: 80 },
  ];

  const eventHistory = [
    { name: 'Raid Deluzhnoa', date: '2024-03-10' },
    { name: 'Evento PvP', date: '2024-03-08' },
    { name: 'Cacería de Minezerok', date: '2024-03-05' },
  ];

  const auctionHistory = [
    { item: 'Espada legendaria', date: '2024-03-12', result: 'Ganada' },
    { item: 'Armadura épica', date: '2024-03-09', result: 'Perdida' },
  ];

  const generateUpcomingBosses = () => {
    const today = moment();
    const bosses = [
      { name: 'Queen Blendy', duration: 3, icon: '/img/QueenBlendy.webp' },
      { name: 'Aridus', duration: 2, icon: '/img/Aridus.webp' },
      { name: 'Chernobog', duration: 4, icon: '/img/Chernobog.webp' },
      { name: 'Minezerok', duration: 3, icon: '/img/Minezerok.webp' },
      { name: 'Deluzhnoa', duration: 2, icon: '/img/Deluzhnoa.webp' },
    ];

    return bosses.map((boss, index) => {
      const date = moment(today).add(index * 2, 'days');
      return {
        ...boss,
        date: date.format('YYYY-MM-DD'),
        time: date.format('HH:mm'),
      };
    });
  };

  const upcomingBosses = generateUpcomingBosses();

  const calendarEvents = upcomingBosses.map(boss => {
    const startDate = moment(`${boss.date} ${boss.time}`).toDate();
    const endDate = moment(startDate).add(boss.duration, 'hours').toDate();
    return {
      title: boss.name,
      start: startDate,
      end: endDate,
    };
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white p-4">
      <header className="text-center mb-8">
        <h1 className="text-3xl md:text-5xl font-bold mb-4">Bienvenido a tu Panel de Guild</h1>
        <p className="text-lg md:text-xl">Tu centro de información y actividad en la guild.</p>
      </header>
      <main className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-6xl">
        <section className="bg-gray-800 p-4 md:p-6 rounded-lg shadow-lg md:col-span-1">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-yellow-400">Anuncios de la Alianza</h2>
          <div className="space-y-4 md:space-y-6">
            {guildAnnouncements.map((announcement, index) => (
              <div key={index} className="bg-gray-700 p-3 md:p-4 rounded-lg border-l-4 border-yellow-400 shadow-md">
                <h3 className="text-lg md:text-xl font-semibold mb-2 text-yellow-300">{announcement.title}</h3>
                <p className="text-sm md:text-base text-gray-300">{announcement.content}</p>
                <div className="mt-3 flex flex-col md:flex-row justify-between items-start md:items-center">
                  <span className="text-xs md:text-sm text-gray-400 mb-2 md:mb-0">Publicado: {announcement.date}</span>
                  <button className="px-3 py-1 bg-yellow-600 text-white rounded-full hover:bg-yellow-500 transition duration-300 ease-in-out text-sm">
                    Leer más
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-gray-800 p-4 md:p-6 rounded-lg">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Próximos Jefes</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {upcomingBosses.map((boss, index) => (
              <div key={index} className="flex flex-col items-center">
                <img src={boss.icon} alt={boss.name} className="w-12 h-12 md:w-16 md:h-16 rounded-full mb-2" />
                <p className="text-center text-sm md:text-base">{boss.name}</p>
                <p className="text-xs md:text-sm text-gray-400">{boss.date}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-gray-800 p-4 md:p-6 rounded-lg md:col-span-2">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Puntos DKP (Últimos 7 días)</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="h-64 md:h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={dkpData}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="points" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="h-64 md:h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={dkpData}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="points" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </section>

        <section className="bg-gray-800 p-4 md:p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 text-green-400">Historial de Eventos</h2>
          <div className="space-y-3">
            {eventHistory.map((event, index) => (
              <div key={index} className="bg-gray-700 p-3 rounded-md flex flex-col md:flex-row justify-between items-start md:items-center">
                <span className="text-base md:text-lg font-medium mb-2 md:mb-0">{event.name}</span>
                <span className="text-xs md:text-sm bg-green-600 px-2 py-1 rounded">{event.date}</span>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-gray-800 p-4 md:p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 text-purple-400">Historial de Subastas</h2>
          <div className="space-y-3">
            {auctionHistory.map((auction, index) => (
              <div key={index} className="bg-gray-700 p-3 rounded-md">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                  <span className="text-base md:text-lg font-medium mb-2 md:mb-0">{auction.item}</span>
                  <span className={`text-xs md:text-sm px-2 py-1 rounded ${
                    auction.result === 'Ganada' ? 'bg-green-600' : 'bg-red-600'
                  }`}>
                    {auction.result}
                  </span>
                </div>
                <span className="text-xs md:text-sm text-gray-400">{auction.date}</span>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-gray-800 p-4 md:p-6 rounded-lg md:col-span-2">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Calendario de Eventos</h2>
          <StyledCalendarWrapper className="h-96">
            <Calendar
              localizer={localizer}
              events={calendarEvents}
              startAccessor="start"
              endAccessor="end"
              className="h-full"
              defaultView="month"
              views={['month', 'week', 'day']}
            />
          </StyledCalendarWrapper>
        </section>
      </main>
      <footer className="text-center mt-8">
        <p className="text-xs md:text-sm">&copy; 2024 Guild. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default Inicio;
