export interface WeaponCombination {
    id: number;
    weapons: string[];
    role: string;
}

const weaponCombinations: WeaponCombination[] = [
            {
              "id": 1,
              "weapons": ["Greatsword", "Sword and Shield"],
              "role": "Tank"
            },
            {
              "id": 2,
              "weapons": ["Greatsword", "Staff"],
              "role": "DPS"
            },
            {
              "id": 3,
              "weapons": ["Greatsword", "Wand"],
              "role": "DPS"
            },
            {
              "id": 4,
              "weapons": ["Greatsword", "Dagger"],
              "role": "DPS"
            },
            {
              "id": 5,
              "weapons": ["Greatsword", "Crossbow"],
              "role": "DPS"
            },
            {
              "id": 6,
              "weapons": ["Greatsword", "Bow"],
              "role": "DPS"
            },
            {
              "id": 7,
              "weapons": ["Sword and Shield", "Staff"],
              "role": "Tank"
            },
            {
              "id": 8,
              "weapons": ["Sword and Shield", "Wand"],
              "role": "Tank"
            },
            {
              "id": 9,
              "weapons": ["Sword and Shield", "Dagger"],
              "role": "Tank"
            },
            {
              "id": 10,
              "weapons": ["Sword and Shield", "Crossbow"],
              "role": "Tank"
            },
            {
              "id": 11,
              "weapons": ["Sword and Shield", "Bow"],
              "role": "Tank"
            },
            {
              "id": 12,
              "weapons": ["Staff", "Wand"],
              "role": "Healer"
            },
            {
              "id": 13,
              "weapons": ["Staff", "Dagger"],
              "role": "DPS"
            },
            {
              "id": 14,
              "weapons": ["Staff", "Crossbow"],
              "role": "DPS"
            },
            {
              "id": 15,
              "weapons": ["Staff", "Bow"],
              "role": "DPS"
            },
            {
              "id": 16,
              "weapons": ["Wand", "Dagger"],
              "role": "Healer"
            },
            {
              "id": 17,
              "weapons": ["Wand", "Crossbow"],
              "role": "DPS"
            },
            {
              "id": 18,
              "weapons": ["Wand", "Bow"],
              "role": "Healer"
            },
            {
              "id": 19,
              "weapons": ["Dagger", "Crossbow"],
              "role": "DPS"
            },
            {
              "id": 20,
              "weapons": ["Dagger", "Bow"],
              "role": "DPS"
            },
            {
              "id": 21,
              "weapons": ["Crossbow", "Bow"],
              "role": "DPS"
            }
          ];

export default weaponCombinations;
