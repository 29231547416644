import React from 'react';

interface GuildMember {
  id: string;
  name: string;
  class: string;
  role: 'DPS' | 'Tank' | 'Healer';
}

interface GuildMembersProps {
  guildMembers: GuildMember[];
  memberFilter: string;
  setMemberFilter: (filter: string) => void;
  segmentedMembers: { [key: string]: GuildMember[] };
  toggleMemberInParty: (member: GuildMember, partyId: string) => void;
  selectedPartyId: string | null;
  isInAnyParty: (memberId: string) => boolean;
  generateParties: (count: number) => void;
  generateAllParties: () => void;
}

const GuildMembers: React.FC<GuildMembersProps> = ({
  segmentedMembers,
  toggleMemberInParty,
  selectedPartyId,
  isInAnyParty,
  memberFilter,
}) => {
  return (
    <div className="flex flex-col h-full space-y-2">
      {['Tank', 'Healer', 'DPS'].map((role) => (
        <div key={role} className="bg-gray-800 p-2 rounded flex-grow">
          <h3 className="text-lg font-semibold mb-2">{role}</h3>
          <div className="grid grid-cols-3 gap-1">
            {segmentedMembers[role as keyof typeof segmentedMembers]
              .filter((member) =>
                member.name.toLowerCase().includes(memberFilter.toLowerCase())
              )
              .map((member) => {
                const isMemberInParty = isInAnyParty(member.id);
                return (
                  <div
                    key={member.id}
                    className={`p-1 rounded ${
                      isMemberInParty ? 'bg-gray-700 cursor-not-allowed' : 'bg-gray-600 cursor-pointer'
                    }`}
                    onClick={() => {
                      if (!isMemberInParty) {
                        toggleMemberInParty(member, selectedPartyId!);
                      }
                    }}
                  >
                    <div>{member.name}</div>
                  </div>
                );
              })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GuildMembers;