import React, { useState } from 'react';

import { Link, Outlet, useNavigate } from 'react-router-dom';
import { HomeIcon, ArrowPathRoundedSquareIcon, WrenchScrewdriverIcon, UserGroupIcon, BuildingLibraryIcon, ChartBarIcon, CurrencyDollarIcon, UsersIcon, CogIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';

const Dashboard: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const navigate = useNavigate();
  const [guildLogo, setGuildLogo] = useState<string | null>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleProfileClick = () => {
    navigate('/perfil');
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const handleGuildLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setGuildLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen relative">
      {/* Sidenav para escritorio */}
      <div className={`hidden md:block fixed left-0 top-0 ${isSidebarCollapsed ? 'w-20' : 'w-64'} bg-gray-900 text-white min-h-screen z-30 transition-all duration-300 flex flex-col`}>
        <div className="flex-grow p-4 overflow-y-auto">
          {!isSidebarCollapsed && (
            <div className="flex flex-col items-center space-y-2 w-full mb-6">
              <img src="logo/tllogo.png" alt="TL Logo" className="h-8 w-auto" />
              <div className="relative w-full">
                {guildLogo ? (
                  <img src={guildLogo} alt="Guild Logo" className="h-16 w-full object-cover rounded" />
                ) : (
                  <div className="h-16 w-full bg-gray-700 rounded flex items-center justify-center">
                    <span className="text-sm text-gray-400">Guild Banner</span>
                  </div>
                )}
                <label htmlFor="guildLogoUpload" className="absolute bottom-1 right-1 bg-blue-500 rounded-full p-1 cursor-pointer">
                  <PencilIcon className="w-4 h-4 text-white" />
                </label>
                <input
                  id="guildLogoUpload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleGuildLogoUpload}
                />
              </div>
            </div>
          )}
          <div 
            className={`bg-gray-800 rounded-lg p-2 shadow-inner flex items-center mb-6 ${isSidebarCollapsed ? 'justify-center' : ''} cursor-pointer`}
            onClick={handleProfileClick}
          >
            <div className={`bg-purple-600 rounded-full flex items-center justify-center text-white font-bold shadow-lg ${isSidebarCollapsed ? 'w-10 h-10 text-sm' : 'w-12 h-12 text-lg'}`}>
              TG
            </div>
            {!isSidebarCollapsed && (
              <div className="ml-3">
                <p className="text-sm font-bold">Usuario123</p>
                <p className="text-xs text-gray-400">Tu Guild</p>
              </div>
            )}
          </div>
          {!isSidebarCollapsed && <h2 className="text-xl font-bold mb-4">Menu</h2>}
          <ul>
            <li className="mb-2">
              <Link to="/" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <HomeIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Inicio</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/ruleta" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <ArrowPathRoundedSquareIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Ruleta</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/build" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <WrenchScrewdriverIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Builds</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/mi-guild" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <UserGroupIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Mi Guild</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/mi-alianza" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <BuildingLibraryIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Mi Alianza</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/aplicaciones" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <ClipboardDocumentListIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Aplicaciones</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/dkp" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <ChartBarIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Asistencias</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/subasta" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <CurrencyDollarIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Subasta</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/party-guild" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <UsersIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Party Guild</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/strategy" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <ChartBarIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Estrategia</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/configuracion" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <CogIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">DKP y eventos</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/tareas" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <ClipboardDocumentListIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Tareas</span>}
              </Link>
            </li>
            <li className="mb-2">
              <Link to="/settings" className={`text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block ${isSidebarCollapsed ? 'text-center' : ''}`}>
                <CogIcon className="inline-block w-4 h-4" />
                {!isSidebarCollapsed && <span className="ml-2">Configuración</span>}
              </Link>
            </li>
          </ul>
        </div>
        
        {/* Botón para colapsar el menú */}
        <div className="p-4 flex justify-center">
          <button 
            onClick={toggleSidebar} 
            className="text-white focus:outline-none hover:bg-gray-700 p-2 rounded-full transition-colors duration-200"
          >
            {isSidebarCollapsed ? (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
              </svg>
            ) : (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Contenido principal */}
      <div className={`flex-grow bg-black min-h-screen text-white ${isSidebarCollapsed ? 'md:ml-20' : 'md:ml-64'} relative z-10 pb-16 md:pb-0 transition-all duration-300`}>
        <Outlet />
      </div>

      {/* Menú inferior para móviles */}
      <div className={`md:hidden fixed bottom-0 left-0 right-0 bg-gray-900 text-white transition-all duration-300 ease-in-out ${isMenuOpen ? 'h-auto max-h-[80vh] overflow-y-auto' : 'h-12 overflow-hidden'} z-50`}>
        <div className="flex justify-between items-center px-4 h-12 sticky top-0 bg-gray-900">
          <span className="text-lg font-bold">Menu</span>
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              {isMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
        <ul className="px-4 py-2">
          <li className="mb-2">
            <div 
              className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block cursor-pointer"
              onClick={handleProfileClick}
            >
              Perfil
            </div>
          </li>
          <li className="mb-2">
            <Link to="/" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Inicio
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/ruleta" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Ruleta
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/build" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Builds
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/mi-guild" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Mi Guild
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/mi-alianza" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Mi Alianza
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/aplicaciones" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Aplicaciones
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/dkp" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Asistencias
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/subasta" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Subasta
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/party-guild" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Party Guild
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/strategy" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Estrategia
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/configuracion" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              DKP y eventos
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/tareas" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Tareas
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/settings" className="text-blue-400 hover:bg-blue-700 hover:text-white px-2 py-1 rounded block" onClick={() => setIsMenuOpen(false)}>
              Configuración
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;