import React, { useState, useMemo } from 'react';

interface Item {
  id: string;
  name: string;
  description: string;
}

interface GuildMember {
  id: string;
  name: string;
  dkp: number;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  winner: GuildMember | null;
  winningBid: number;
}

const WinnerModal: React.FC<ModalProps> = ({ isOpen, onClose, winner, winningBid }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg max-w-sm w-full">
        <h2 className="text-2xl font-bold mb-4">Subasta Finalizada</h2>
        {winner ? (
          <>
            <p className="mb-2">Ganador: {winner.name}</p>
            <p className="mb-4">Puja ganadora: {winningBid} DKP</p>
          </>
        ) : (
          <p className="mb-4">No hubo ganador</p>
        )}
        <button 
          onClick={onClose}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

const Subasta: React.FC = () => {
  const items: Item[] = useMemo(() => [
    { id: '1', name: 'Espada legendaria', description: 'Una espada de gran poder' },
    { id: '2', name: 'Armadura de dragón', description: 'Protección contra el fuego' },
    { id: '3', name: 'Amuleto de los dioses', description: 'Aumenta todas las estadísticas' },
  ], []);

  const guildMembers: GuildMember[] = useMemo(() => {
    return Array.from({ length: 50 }, (_, index) => ({
      id: (index + 1).toString(),
      name: `Jugador ${index + 1}`,
      dkp: Math.floor(Math.random() * 1000) + 500
    }));
  }, []);

  const [selectedItem, setSelectedItem] = useState<string>('');
  const [minBid, setMinBid] = useState<number>(0);
  const [burnDKP, setBurnDKP] = useState<boolean>(false);
  const [auctionStarted, setAuctionStarted] = useState<boolean>(false);
  const [currentBids, setCurrentBids] = useState<{[key: string]: number}>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [winnerMember, setWinnerMember] = useState<GuildMember | null>(null);
  const [winningBid, setWinningBid] = useState(0);
  const [leadingBidder, setLeadingBidder] = useState<GuildMember | null>(null);
  const [selectedMember, setSelectedMember] = useState<string | null>(null);
  const [highestBid, setHighestBid] = useState<number>(0);

  const handleItemChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedItem(e.target.value);
  };

  const handleMinBidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinBid(Number(e.target.value));
  };

  const handleBurnDKPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBurnDKP(e.target.checked);
  };

  const startAuction = () => {
    if (!selectedItem || minBid <= 0) {
      alert('Por favor, selecciona un ítem y establece una puja mínima válida.');
      return;
    }
    setAuctionStarted(true);
    setCurrentBids({});
    setHighestBid(minBid - 1);
    setLeadingBidder(null);
  };

  const canBid = (member: GuildMember) => {
    return member.dkp > highestBid;
  };

  const handleBidChange = (memberId: string, value: string) => {
    const numValue = Number(value);
    if (!isNaN(numValue) && numValue > highestBid && numValue <= guildMembers.find(m => m.id === memberId)!.dkp) {
      placeBid(memberId, numValue);
    }
  };

  const placeBid = (memberId: string, bidAmount: number) => {
    const member = guildMembers.find(m => m.id === memberId);
    if (member && bidAmount <= member.dkp && bidAmount > highestBid) {
      setCurrentBids(prev => ({...prev, [memberId]: bidAmount}));
      setHighestBid(bidAmount);
      setLeadingBidder(member);
    }
  };

  const endAuction = () => {
    const winner = leadingBidder;
    setWinnerMember(winner);
    setWinningBid(highestBid);
    setModalOpen(true);
    setAuctionStarted(false);

    if (burnDKP) {
      console.log('Quemando DKP de todos los miembros');
    }
  };

  const bidAllDKP = (memberId: string) => {
    const member = guildMembers.find(m => m.id === memberId);
    if (member && member.dkp > highestBid) {
      placeBid(memberId, member.dkp);
    }
  };

  const selectMember = (memberId: string) => {
    setSelectedMember(memberId);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4 flex flex-col overflow-hidden">
      <div className="flex-grow flex flex-col max-w-7xl mx-auto w-full overflow-hidden">
        <h1 className="text-3xl font-bold mb-6">Sistema de Subastas</h1>
        
        <div className="flex-grow flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 overflow-hidden">
          <div className="lg:w-1/2 flex flex-col space-y-6 overflow-hidden">
            {!auctionStarted ? (
              <div className="bg-gray-800 rounded-lg shadow-xl p-6 flex-grow overflow-auto">
                <h2 className="text-2xl font-bold mb-4">Configurar Subasta</h2>
                <div className="space-y-4">
                  <div>
                    <label className="block mb-2">Seleccionar Ítem</label>
                    <select 
                      value={selectedItem} 
                      onChange={handleItemChange}
                      className="w-full p-2 bg-gray-700 rounded"
                    >
                      <option value="">Selecciona un ítem</option>
                      {items.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2">Puja Mínima</label>
                    <input 
                      type="number" 
                      value={minBid} 
                      onChange={handleMinBidChange}
                      className="w-full p-2 bg-gray-700 rounded"
                    />
                  </div>
                  <div className="flex items-center">
                    <input 
                      type="checkbox" 
                      checked={burnDKP} 
                      onChange={handleBurnDKPChange} 
                      className="mr-2"
                    />
                    <label>Quemar DKP de la guild al finalizar</label>
                  </div>
                  <button 
                    onClick={startAuction}
                    className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    Iniciar Subasta
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-gray-800 rounded-lg shadow-xl p-6 flex flex-col flex-grow overflow-hidden">
                <h2 className="text-2xl font-bold mb-4">Subasta en Progreso</h2>
                <p className="mb-2">Ítem: {items.find(i => i.id === selectedItem)?.name}</p>
                <p className="mb-2">Puja mínima: {minBid} DKP</p>
                <p className="mb-2">Puja más alta: {highestBid} DKP</p>
                {leadingBidder && (
                  <div className="bg-blue-600 p-2 rounded mb-4">
                    <p className="font-bold">Pujador Líder: {leadingBidder.name}</p>
                    <p>Puja actual: {currentBids[leadingBidder.id]} DKP</p>
                  </div>
                )}
                <div className="flex-grow overflow-auto">
                  {selectedMember ? (
                    <MemberBidCard 
                      member={guildMembers.find(m => m.id === selectedMember)!}
                      currentBid={currentBids[selectedMember] || minBid}
                      minBid={Math.max(minBid, highestBid + 1)}
                      onBidChange={(value) => handleBidChange(selectedMember, value)}
                      onMaxBid={() => bidAllDKP(selectedMember)}
                    />
                  ) : (
                    <p className="text-center text-gray-400">Selecciona un miembro para pujar</p>
                  )}
                </div>
                <button 
                  onClick={endAuction}
                  className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300 mt-4"
                >
                  Finalizar Subasta
                </button>
              </div>
            )}
          </div>

          <div className="lg:w-1/2 flex flex-col space-y-6 overflow-hidden">
            {auctionStarted && (
              <div className="bg-gray-800 rounded-lg shadow-xl p-6 flex-grow flex flex-col overflow-hidden">
                <h2 className="text-2xl font-bold mb-4">Miembros Elegibles</h2>
                <div className="flex-grow overflow-auto">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-2">
                    {guildMembers.map(member => {
                      const isEligible = canBid(member);
                      const currentBid = currentBids[member.id] || 0;
                      return (
                        <button
                          key={member.id}
                          onClick={() => isEligible && selectMember(member.id)}
                          className={`p-2 rounded text-left ${
                            member.id === selectedMember
                              ? 'bg-blue-600'
                              : isEligible
                              ? 'bg-gray-700 hover:bg-gray-600'
                              : 'bg-gray-800 text-gray-500 cursor-not-allowed'
                          }`}
                          disabled={!isEligible}
                        >
                          <p className="font-semibold text-sm truncate">{member.name}</p>
                          <p className="text-xs">DKP: {member.dkp}</p>
                          {currentBid > 0 && (
                            <p className="text-xs text-green-400">Puja: {currentBid}</p>
                          )}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <WinnerModal 
        isOpen={modalOpen} 
        onClose={() => setModalOpen(false)} 
        winner={winnerMember} 
        winningBid={winningBid}
      />
    </div>
  );
};

interface MemberBidCardProps {
  member: GuildMember;
  currentBid: number;
  minBid: number;
  onBidChange: (value: string) => void;
  onMaxBid: () => void;
}

const MemberBidCard: React.FC<MemberBidCardProps> = ({ member, currentBid, minBid, onBidChange, onMaxBid }) => {
  return (
    <div className="bg-gray-700 p-4 rounded">
      <h3 className="text-xl font-bold mb-2">{member.name}</h3>
      <p className="mb-2">DKP disponible: {member.dkp}</p>
      <p className="mb-2">Puja actual: {currentBid} DKP</p>
      <div className="flex space-x-2">
        <input
          type="number"
          value={currentBid}
          onChange={(e) => onBidChange(e.target.value)}
          className="flex-grow p-2 bg-gray-600 rounded"
          min={minBid}
          max={member.dkp}
        />
        <button
          onClick={onMaxBid}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Pujar Todo
        </button>
      </div>
    </div>
  );
};

export default Subasta;