import React, { useState, useEffect } from 'react';

interface Tarea {
  id: string;
  descripcion: string;
  tipo: 'diaria' | 'semanal' | 'mensual';
  dkp: number;
  asignada: string | null;
  completada: boolean;
}

interface NuevaTarea {
  descripcion: string;
  tipo: 'diaria' | 'semanal' | 'mensual';
  dkp: string;  // Cambiamos esto a string
}

const Tareas: React.FC = () => {
  const [tareas, setTareas] = useState<Tarea[]>([]);
  const [nuevaTarea, setNuevaTarea] = useState<NuevaTarea>({
    descripcion: '',
    tipo: 'diaria',
    dkp: '',  // Ahora es un string vacío
  });

  useEffect(() => {
    // Aquí cargarías las tareas desde tu backend
    const tareasEjemplo: Tarea[] = [
      { id: '1', descripcion: 'Loguearse diariamente', tipo: 'diaria', dkp: 5, asignada: null, completada: false },
      { id: '2', descripcion: 'Completar misión semanal', tipo: 'semanal', dkp: 20, asignada: 'Usuario1', completada: false },
    ];
    setTareas(tareasEjemplo);
  }, []);

  const handleDkpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Permitimos string vacío o números positivos
    if (value === '' || /^\d+$/.test(value)) {
      setNuevaTarea({ ...nuevaTarea, dkp: value });
    }
  };

  const crearTarea = () => {
    const nuevaTareaCompleta: Tarea = {
      ...nuevaTarea,
      id: Date.now().toString(),
      asignada: null,
      completada: false,
      dkp: parseInt(nuevaTarea.dkp) || 0,  // Convertimos a número aquí
    };
    setTareas([...tareas, nuevaTareaCompleta]);
    setNuevaTarea({ descripcion: '', tipo: 'diaria', dkp: '' });
  };

  const tomarTarea = (id: string) => {
    setTareas(tareas.map(tarea => 
      tarea.id === id ? { ...tarea, asignada: 'UsuarioActual' } : tarea
    ));
  };

  const completarTarea = (id: string) => {
    setTareas(tareas.map(tarea => 
      tarea.id === id ? { ...tarea, completada: true } : tarea
    ));
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-[1920px] mx-auto flex flex-col md:flex-row gap-4">
        {/* Columna izquierda: Formulario de crear nueva tarea */}
        <div className="md:w-1/3 bg-gray-800 rounded-lg shadow-xl p-6">
          <h2 className="text-2xl font-bold mb-4">Crear Nueva Tarea</h2>
          <div className="flex flex-col gap-4">
            <input
              type="text"
              value={nuevaTarea.descripcion}
              onChange={(e) => setNuevaTarea({ ...nuevaTarea, descripcion: e.target.value })}
              placeholder="Descripción de la tarea"
              className="bg-gray-700 p-2 rounded w-full"
            />
            <div className="flex flex-col space-y-2">
              {['diaria', 'semanal', 'mensual'].map((tipo) => (
                <button
                  key={tipo}
                  onClick={() => setNuevaTarea({ ...nuevaTarea, tipo: tipo as 'diaria' | 'semanal' | 'mensual' })}
                  className={`py-2 px-4 rounded transition duration-300 ${
                    nuevaTarea.tipo === tipo ? 'bg-blue-600' : 'bg-gray-700 hover:bg-gray-600'
                  }`}
                >
                  {tipo.charAt(0).toUpperCase() + tipo.slice(1)}
                </button>
              ))}
            </div>
            <input
              type="text"
              value={nuevaTarea.dkp}
              onChange={handleDkpChange}
              placeholder="DKP"
              className="bg-gray-700 p-2 rounded w-full"
            />
            <button 
              onClick={crearTarea}
              className="bg-green-600 hover:bg-green-700 py-2 px-4 rounded transition duration-300 w-full"
            >
              Crear Tarea
            </button>
          </div>
        </div>

        {/* Columna derecha: Mis Tareas y Tareas Disponibles */}
        <div className="md:w-2/3 flex flex-col gap-4">
          {/* Mis Tareas */}
          <div className="bg-gray-800 rounded-lg shadow-xl p-6">
            <h2 className="text-2xl font-bold mb-4">Mis Tareas</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {tareas.filter(tarea => tarea.asignada === 'UsuarioActual').map(tarea => (
                <div key={tarea.id} className="bg-gray-700 p-4 rounded-lg flex flex-col justify-between">
                  <div>
                    <h3 className="font-semibold mb-2">{tarea.descripcion}</h3>
                    <p className="text-sm mb-2">{tarea.dkp} DKP</p>
                  </div>
                  <button 
                    onClick={() => completarTarea(tarea.id)} 
                    disabled={tarea.completada}
                    className={`w-full py-2 px-4 rounded transition duration-300 ${
                      tarea.completada 
                        ? 'bg-gray-600 cursor-not-allowed' 
                        : 'bg-green-600 hover:bg-green-700'
                    }`}
                  >
                    {tarea.completada ? 'Completada' : 'Completar'}
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Tareas Disponibles */}
          <div className="bg-gray-800 rounded-lg shadow-xl p-6">
            <h2 className="text-2xl font-bold mb-4">Tareas Disponibles</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {tareas.filter(tarea => !tarea.asignada).map(tarea => (
                <div key={tarea.id} className="bg-gray-700 p-4 rounded-lg flex flex-col justify-between">
                  <div>
                    <h3 className="font-semibold mb-2">{tarea.descripcion}</h3>
                    <p className="text-sm mb-2">{tarea.dkp} DKP</p>
                  </div>
                  <button 
                    onClick={() => tomarTarea(tarea.id)}
                    className="w-full bg-blue-600 hover:bg-blue-700 py-2" 
                  >
                    Tomar Tarea
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tareas;