import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Inicio from './components/Inicio';
import Ruleta from './components/Ruleta';
import BuildList from './components/BuildList';
import MiGuild from './components/MiGuild';
import Configuracion from './components/Configuracion';
import DKP from './components/DKP';
import Subasta from './components/Subasta';
import PartyGuild from './components/PartyGuild';
import MiAlianza from './components/MiAlianza';
import GuildDetail from './components/GuildDetail';
import NotFound from './components/NotFound';
import Perfil from './components/Perfil';
import Aplicaciones from './components/Aplicaciones'; // Importación corregida
import Strategy from './components/Strategy'; // Add this import
import Tareas from './components/Tareas'; // Add this import
import Settings from './components/Settings'; // Add this import

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const ProtectedRoute: React.FC = () => {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }
    return <Outlet />;
  };

  return (
    <Router>
      <Routes>
        <Route 
          path="/login" 
          element={
            <Login 
              setIsAuthenticated={setIsAuthenticated} 
              isAuthenticated={isAuthenticated} 
            />
          } 
        />
        <Route 
          path="/oauth/callback" 
          element={
            <Login 
              setIsAuthenticated={setIsAuthenticated} 
              isAuthenticated={isAuthenticated} 
            />
          } 
        />
        <Route path="/404" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />}>
            <Route index element={<Inicio />} />
            <Route path="ruleta" element={<Ruleta />} />
            <Route path="/build" element={<BuildList />} />
            <Route path="/mi-guild" element={<MiGuild />} />
            <Route path="/mi-alianza" element={<MiAlianza />} />
            <Route path="/dkp" element={<DKP />} />
            <Route path="/subasta" element={<Subasta />} />
            <Route path="/party-guild" element={<PartyGuild />} />
            <Route path="/configuracion" element={<Configuracion />} />
            <Route path="/guild/:id" element={<GuildDetail />} />
            <Route path="/perfil" element={<Perfil />} />
            <Route path="/aplicaciones" element={<Aplicaciones />} /> // Ruta corregida
            <Route path="/strategy" element={<Strategy />} /> // New protected route for Strategy
            <Route path="/tareas" element={<Tareas />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Router>
  );
};

export default App;