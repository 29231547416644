export interface Build {
  id: number;
  name: string;
  icon1: string;
  icon2: string;
  type: string;
  author: string;
  time: string;
  pveRating: number;
  pvpRating: number;
  rating: number;
  category?: string; // Hacemos que category sea opcional
}

export interface BuildsData {
  healer: Build[];
  tank: Build[];
  dps: Build[];
}

export const initialTestBuildsData: BuildsData = {
  "healer": [
    { "id": 1, "name": "Life Bringer", "type": "Staff/Wand", "author": "user1", "time": "2 hr. ago", "icon1": "staff.png", "icon2": "wand.png", "rating": 9, "pveRating": 5, "pvpRating": 4 },
    { "id": 2, "name": "Arcane Medic", "type": "Wand/Bow", "author": "user2", "time": "3 hr. ago", "icon1": "wand.png", "icon2": "bow.png", "rating": 8, "pveRating": 4, "pvpRating": 3 },
    { "id": 3, "name": "Swift Healer", "type": "Wand/Dagger", "author": "user3", "time": "4 hr. ago", "icon1": "wand.png", "icon2": "dagger.png", "rating": 7, "pveRating": 3, "pvpRating": 4 }
  ],
  "tank": [
    { "id": 4, "name": "Bulwark", "type": "Shield/Greatsword", "author": "user4", "time": "1 hr. ago", "icon1": "sns.png", "icon2": "greatsword.png", "rating": 9, "pveRating": 5, "pvpRating": 4 },
    { "id": 5, "name": "Arcane Protector", "type": "Shield/Staff", "author": "user5", "time": "2 hr. ago", "icon1": "sns.png", "icon2": "staff.png", "rating": 7, "pveRating": 4, "pvpRating": 3 },
    { "id": 6, "name": "Evasive Guardian", "type": "Shield/Dagger", "author": "user6", "time": "3 hr. ago", "icon1": "sns.png", "icon2": "dagger.png", "rating": 8, "pveRating": 3, "pvpRating": 5 },
    { "id": 7, "name": "Mystical Shield", "type": "Shield/Wand", "author": "user7", "time": "4 hr. ago", "icon1": "sns.png", "icon2": "wand.png", "rating": 6, "pveRating": 3, "pvpRating": 4 },
    { "id": 8, "name": "Ranged Defender", "type": "Shield/Bow", "author": "user8", "time": "5 hr. ago", "icon1": "sns.png", "icon2": "bow.png", "rating": 7, "pveRating": 4, "pvpRating": 3 }
  ],
  "dps": [
    { "id": 9, "name": "Archers Precision", "type": "Bow/Dagger", "author": "user9", "time": "1 hr. ago", "icon1": "bow.png", "icon2": "dagger.png", "rating": 8, "pveRating": 4, "pvpRating": 5 },
    { "id": 10, "name": "Spell Sword", "type": "Greatsword/Staff", "author": "user10", "time": "2 hr. ago", "icon1": "greatsword.png", "icon2": "staff.png", "rating": 8, "pveRating": 4, "pvpRating": 5 },
    { "id": 11, "name": "Arcane Blade", "type": "Greatsword/Wand", "author": "user11", "time": "3 hr. ago", "icon1": "greatsword.png", "icon2": "wand.png", "rating": 7, "pveRating": 3, "pvpRating": 4 },
    { "id": 12, "name": "Shadow Strike", "type": "Greatsword/Dagger", "author": "user12", "time": "4 hr. ago", "icon1": "greatsword.png", "icon2": "dagger.png", "rating": 9, "pveRating": 5, "pvpRating": 4 },
    { "id": 13, "name": "Mystic Archer", "type": "Bow/Staff", "author": "user13", "time": "5 hr. ago", "icon1": "bow.png", "icon2": "staff.png", "rating": 8, "pveRating": 4, "pvpRating": 3 },
    { "id": 14, "name": "Swift Shot", "type": "Bow/Crossbow", "author": "user14", "time": "6 hr. ago", "icon1": "bow.png", "icon2": "crossbow.png", "rating": 7, "pveRating": 3, "pvpRating": 4 },
    { "id": 15, "name": "Arcane Sniper", "type": "Crossbow/Wand", "author": "user15", "time": "7 hr. ago", "icon1": "crossbow.png", "icon2": "wand.png", "rating": 8, "pveRating": 4, "pvpRating": 5 },
    { "id": 16, "name": "Shadow Hunter", "type": "Crossbow/Dagger", "author": "user16", "time": "8 hr. ago", "icon1": "crossbow.png", "icon2": "dagger.png", "rating": 7, "pveRating": 3, "pvpRating": 4 },
    { "id": 17, "name": "Mystic Bolt", "type": "Crossbow/Staff", "author": "user17", "time": "9 hr. ago", "icon1": "crossbow.png", "icon2": "staff.png", "rating": 6, "pveRating": 3, "pvpRating": 3 },
    { "id": 18, "name": "Arcane Dagger", "type": "Dagger/Staff", "author": "user18", "time": "10 hr. ago", "icon1": "dagger.png", "icon2": "staff.png", "rating": 7, "pveRating": 3, "pvpRating": 4 },
    { "id": 19, "name": "Spell Fencer", "type": "Greatsword/Bow", "author": "user19", "time": "11 hr. ago", "icon1": "greatsword.png", "icon2": "bow.png", "rating": 8, "pveRating": 4, "pvpRating": 4 },
    { "id": 20, "name": "Dual Range", "type": "Bow/Wand", "author": "user20", "time": "12 hr. ago", "icon1": "bow.png", "icon2": "wand.png", "rating": 9, "pveRating": 5, "pvpRating": 4 },
    { "id": 21, "name": "Versatile Warrior", "type": "Greatsword/Crossbow", "author": "user21", "time": "13 hr. ago", "icon1": "greatsword.png", "icon2": "crossbow.png", "rating": 8, "pveRating": 4, "pvpRating": 5 }
  ]
};