import React, { useState } from 'react';
import styled from 'styled-components';
import { Build } from '../../data/buildsData';

const BuildCardWrapper = styled.div`
  position: relative;
`;

const BuildCardStyled = styled.div<{ icon2: string }>`
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #2a2a2a 0%, #1a1a1a 100%);
  border: 1px solid #3a3a3a;
  transition: all 0.3s ease;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-height: 80px;
  max-width: 100%;
  padding: 0.5rem;
  padding-right: 3.5rem;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15%;
    width: 30%;
    height: 120%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.15;
    z-index: 0;
    transform: translateY(-50%);
    background-image: ${props => `url(/icon/${props.icon2.replace('.png', '_player.png')})`};
  }

  &:hover {
    background: linear-gradient(135deg, #4a0080 50%, #2a004d 30%);
    border-color: #6600b3;
    box-shadow: 0 0 10px rgba(102, 0, 179, 0.3);
  }

  @media (min-width: 640px) {
    min-height: 100px;
    padding: 0.75rem;
    padding-right: 4.5rem;
  }
`;

const BuildContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
`;

const WeaponIcons = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;

  @media (min-width: 640px) {
    margin-right: 0.75rem;
  }
`;

const WeaponIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  @media (min-width: 640px) {
    width: 24px;
    height: 24px;
  }
`;

const BuildInfo = styled.div`
  position: relative;
  flex-grow: 1;
  max-width: calc(100% - 5rem);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: 640px) {
    max-width: calc(100% - 6rem);
  }
`;

const BuildName = styled.h2`
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0.125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 640px) {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
`;

const BuildType = styled.p`
  font-size: 0.625rem;
  color: #a0aec0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 640px) {
    font-size: 0.75rem;
  }
`;

const BuildDetails = styled.p`
  font-size: 0.625rem;
  color: #a0aec0;
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;

  @media (min-width: 640px) {
    font-size: 0.75rem;
  }
`;

const RatingWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;
`;

const RatingLabel = styled.span`
  margin-right: 0.25rem;
  min-width: 40px;
`;

const RatingStars = styled.span`
  color: #b8860b; // Darker gold color
`;

const PlayerIcon = styled.img`
  position: absolute;
  right: -1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 7rem;
  height: 7rem;
  z-index: 10;

  @media (min-width: 640px) {
    right: -1rem;
    width: 7rem;
    height: 7rem;
  }
`;

const ActionLabel = styled.span`
  color: #808080;
  font-size: 0.75rem;
  cursor: pointer;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  align-self: flex-start;
  transition: color 0.3s ease;

  &:hover {
    color: #4a90e2;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

interface BuildCardProps {
  build: Build;
  onUpdate: (build: Build) => void;
  disableClick?: boolean;
}

const BuildCard: React.FC<BuildCardProps> = ({ build, onUpdate, disableClick }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedPveRating, setEditedPveRating] = useState(build.pveRating);
  const [editedPvpRating, setEditedPvpRating] = useState(build.pvpRating);

  const handleStarClick = (ratingType: 'pve' | 'pvp', starIndex: number) => {
    if (isEditing) {
      if (ratingType === 'pve') {
        setEditedPveRating(starIndex + 1);
      } else {
        setEditedPvpRating(starIndex + 1);
      }
    }
  };

  const handleSave = () => {
    onUpdate({ ...build, pveRating: editedPveRating, pvpRating: editedPvpRating });
    setIsEditing(false);
  };

  const renderStars = (rating: number, ratingType: 'pve' | 'pvp') => {
    return [...Array(5)].map((_, index) => (
      <span
        key={index}
        onClick={(e) => {
          e.stopPropagation();
          if (isEditing) {
            handleStarClick(ratingType, index);
          }
        }}
        style={{ cursor: isEditing ? 'pointer' : 'default' }}
      >
        {index < rating ? '★' : '☆'}
      </span>
    ));
  };

  return (
    <BuildCardWrapper className="relative">
      <BuildCardStyled 
        className="rounded-lg"
        icon2={build.icon2}
        onClick={disableClick ? undefined : () => {}}
      >
        <BuildContent>
          <WeaponIcons>
            <WeaponIconWrapper className="mb-1">
              <img src={`/icon/${build.icon1}`} alt="Weapon Icon 1" className="w-full h-full rounded-full" />
            </WeaponIconWrapper>
            <WeaponIconWrapper>
              <img src={`/icon/${build.icon2}`} alt="Weapon Icon 2" className="w-full h-full rounded-full" />
            </WeaponIconWrapper>
          </WeaponIcons>
          <BuildInfo>
            <BuildName>{build.name}</BuildName>
            <BuildType>{build.type}</BuildType>
            <BuildDetails>
              <RatingWrapper>
                <RatingLabel>PvE:</RatingLabel>
                <RatingStars>{renderStars(isEditing ? editedPveRating : build.pveRating, 'pve')}</RatingStars>
              </RatingWrapper>
              <RatingWrapper>
                <RatingLabel>PvP:</RatingLabel>
                <RatingStars>{renderStars(isEditing ? editedPvpRating : build.pvpRating, 'pvp')}</RatingStars>
              </RatingWrapper>
            </BuildDetails>
            <ActionsWrapper>
              {isEditing ? (
                <>
                  <ActionLabel onClick={(e) => {
                    e.stopPropagation();
                    handleSave();
                  }}>
                    Guardar
                  </ActionLabel>
                  <ActionLabel onClick={(e) => {
                    e.stopPropagation();
                    setIsEditing(false);
                    setEditedPveRating(build.pveRating);
                    setEditedPvpRating(build.pvpRating);
                  }}>
                    Cancelar
                  </ActionLabel>
                </>
              ) : (
                <ActionLabel onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(true);
                }}>
                  Editar
                </ActionLabel>
              )}
            </ActionsWrapper>
          </BuildInfo>
        </BuildContent>
      </BuildCardStyled>
      <PlayerIcon src={`/icon/${build.icon1.replace('.png', '_player.png')}`} alt="Player Icon" />
    </BuildCardWrapper>
  );
};

export default BuildCard;