import React, { useState, useMemo, useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import GuildMembers from './partyguild/GuildMembers';
import Parties from './partyguild/Parties';

interface GuildMember {
  id: string;
  name: string;
  class: string;
  role: 'DPS' | 'Tank' | 'Healer';
}

interface Party {
  id: string;
  name: string;
  members: (GuildMember | null)[];
  leaderId: string | null;
}

const PartyGuild: React.FC = () => {
  const [parties, setParties] = useState<Party[]>([]);
  const [selectedPartyId, setSelectedPartyId] = useState<string | null>(null);
  const [draggingOverId, setDraggingOverId] = useState<string | null>(null);
  const [memberFilter, setMemberFilter] = useState('');
  const [editingPartyId, setEditingPartyId] = useState<string | null>(null);
  const [editingPartyName, setEditingPartyName] = useState<string>('');

  const guildMembers: GuildMember[] = useMemo(() => 
    Array.from({ length: 50 }, (_, i) => ({
      id: `member-${i + 1}`,
      name: `Member ${i + 1}`,
      class: ['Warrior', 'Mage', 'Priest', 'Rogue', 'Paladin', 'Druid', 'Hunter', 'Warlock'][Math.floor(Math.random() * 8)],
      role: ['DPS', 'Tank', 'Healer'][Math.floor(Math.random() * 3)] as 'DPS' | 'Tank' | 'Healer'
    }))
  , []);

  const filteredMembers = useMemo(() => 
    guildMembers.filter(member => 
      member.name.toLowerCase().includes(memberFilter.toLowerCase()) ||
      member.class.toLowerCase().includes(memberFilter.toLowerCase())
    )
  , [guildMembers, memberFilter]);

  const segmentedMembers = useMemo(() => {
    return {
      Tank: guildMembers.filter(m => m.role === 'Tank'),
      Healer: guildMembers.filter(m => m.role === 'Healer'),
      DPS: guildMembers.filter(m => m.role === 'DPS')
    };
  }, [guildMembers]);

  const createNewParty = () => {
    const newParty: Party = {
      id: `party_${parties.length + 1}`,
      name: `Party ${parties.length + 1}`,
      members: Array(6).fill(null),
      leaderId: null
    };
    setParties([...parties, newParty]);
    setSelectedPartyId(newParty.id);
  };

  const toggleMemberInParty = useCallback((member: GuildMember, partyId: string) => {
    setParties(prevParties => prevParties.map(party => {
      if (party.id === partyId) {
        const existingMember = party.members.find(m => m?.id === member.id);
        if (existingMember) {
          // Remove member
          return { ...party, members: party.members.map(m => m?.id === member.id ? null : m) };
        } else {
          // Add member to the first empty slot
          const emptyIndex = party.members.findIndex(m => m === null);
          if (emptyIndex !== -1) {
            const newMembers = [...party.members];
            newMembers[emptyIndex] = member;
            return { ...party, members: newMembers };
          }
        }
      }
      return party;
    }));
  }, []);

  const isInAnyParty = (memberId: string) => {
    return parties.some(party => party.members.some(member => member?.id === memberId));
  };

  const generateParties = (count: number) => {
    const newParties: Party[] = [];
    const availableMembers = guildMembers.filter(member => !isInAnyParty(member.id));

    for (let i = 0; i < count; i++) {
      if (availableMembers.length < 2) break; // Stop if we don't have enough members for a new party

      const party: Party = {
        id: `party_${parties.length + newParties.length + 1}`,
        name: `Party ${parties.length + newParties.length + 1}`,
        members: Array(6).fill(null),
        leaderId: null
      };

      // Añadir un Tank
      const tank = availableMembers.find(m => m.role === 'Tank');
      if (tank) {
        party.members[0] = tank;
        party.leaderId = tank.id; // Asignar al primer miembro como líder
        availableMembers.splice(availableMembers.indexOf(tank), 1);
      }

      // Añadir un Healer
      const healer = availableMembers.find(m => m.role === 'Healer');
      if (healer) {
        party.members[1] = healer;
        availableMembers.splice(availableMembers.indexOf(healer), 1);
      }

      // Añadir DPS hasta completar 6 miembros o agotar los disponibles
      let dpsIndex = 2;
      while (dpsIndex < 6 && availableMembers.length > 0) {
        const dps = availableMembers.find(m => m.role === 'DPS') || availableMembers[0];
        party.members[dpsIndex] = dps;
        availableMembers.splice(availableMembers.indexOf(dps), 1);
        dpsIndex++;
      }

      newParties.push(party);
    }

    if (newParties.length > 0) {
      setParties(prevParties => [...prevParties, ...newParties]);
    }
  };

  const generateAllParties = useCallback(() => {
    const availableMembers = guildMembers.filter(member => !isInAnyParty(member.id));
    const newParties: Party[] = [];
    let partyIndex = parties.length + 1;

    while (availableMembers.length > 0) {
      const party: Party = {
        id: `party_${partyIndex}`,
        name: `Party ${partyIndex}`,
        members: [],
        leaderId: null
      };

      // Intentar añadir un Tank
      const tank = availableMembers.find(m => m.role === 'Tank');
      if (tank) {
        party.members.push(tank);
        party.leaderId = tank.id; // Asignar al primer miembro como líder
        availableMembers.splice(availableMembers.indexOf(tank), 1);
      }

      // Intentar añadir un Healer
      const healer = availableMembers.find(m => m.role === 'Healer');
      if (healer) {
        party.members.push(healer);
        availableMembers.splice(availableMembers.indexOf(healer), 1);
      }

      // Añadir DPS o cualquier miembro restante hasta completar 6 miembros o agotar los disponibles
      while (party.members.length < 6 && availableMembers.length > 0) {
        const member = availableMembers.shift()!;
        party.members.push(member);
      }

      // Añadir la party solo si tiene al menos 2 miembros
      if (party.members.length >= 2) {
        newParties.push(party);
        partyIndex++;
      }
    }

    if (newParties.length > 0) {
      setParties(prevParties => [...prevParties, ...newParties]);
    }
  }, [guildMembers, parties.length, isInAnyParty]);

  const calculateSynergy = (party: Party) => {
    const roles = party.members
      .filter((m): m is GuildMember => m !== null && m !== undefined)
      .map(m => m.role);
    
    const hasDPS = roles.includes('DPS');
    const hasTank = roles.includes('Tank');
    const hasHealer = roles.includes('Healer');

    if (hasDPS && hasTank && hasHealer) return 100;
    if ((hasDPS && hasTank) || (hasDPS && hasHealer) || (hasTank && hasHealer)) return 66;
    if (roles.length > 0) return 33;
    return 0;
  };

  const onDragStart = useCallback(() => {
    setDraggingOverId(null);
  }, []);

  const onDragUpdate = useCallback((update: any) => {
    if (update.destination) {
      setDraggingOverId(`${update.destination.droppableId}-${update.destination.index}`);
    } else {
      setDraggingOverId(null);
    }
  }, []);

  const onDragEnd = useCallback((result: DropResult) => {
    setDraggingOverId(null);
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    setParties(prevParties => {
      const newParties = [...prevParties];
      const sourceParty = newParties.find(p => p.id === source.droppableId);
      const destParty = newParties.find(p => p.id === destination.droppableId);

      if (sourceParty && destParty) {
        const [movedMember] = sourceParty.members.splice(source.index, 1, null);

        if (source.droppableId === destination.droppableId) {
          destParty.members.splice(destination.index, 1, movedMember);
        } else {
          if (destParty.members.filter(m => m !== null).length < 6) {
            const [replacedMember] = destParty.members.splice(destination.index, 1, movedMember);
            if (replacedMember) {
              sourceParty.members[source.index] = replacedMember;
            }
          } else {
            // Si la party de destino ya tiene 6 miembros, no hacemos nada
            sourceParty.members[source.index] = movedMember;
          }
        }

        // Limpiar nulls y limitar a 6 miembros
        sourceParty.members = sourceParty.members.filter(m => m !== null).slice(0, 6);
        if (source.droppableId !== destination.droppableId) {
          destParty.members = destParty.members.filter(m => m !== null).slice(0, 6);
        }
      }

      return newParties;
    });
  }, []);

  const deleteParty = useCallback((partyId: string) => {
    setParties(prevParties => prevParties.filter(party => party.id !== partyId));
    if (selectedPartyId === partyId) {
      setSelectedPartyId(null);
    }
  }, [selectedPartyId]);

  const startEditingPartyName = useCallback((partyId: string, currentName: string) => {
    setEditingPartyId(partyId);
    setEditingPartyName(currentName);
  }, []);

  const updatePartyName = useCallback((partyId: string) => {
    setParties(prevParties => 
      prevParties.map(party => 
        party.id === partyId ? { ...party, name: editingPartyName } : party
      )
    );
    setEditingPartyId(null);
  }, [editingPartyName]);

  const togglePartyLeader = useCallback((partyId: string, memberId: string) => {
    setParties(prevParties => prevParties.map(party => {
      if (party.id === partyId) {
        return {
          ...party,
          leaderId: party.leaderId === memberId ? null : memberId
        };
      }
      return party;
    }));
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col md:flex-row">
      <div className="w-full md:w-[30%] flex flex-col">
        <h2 className="text-xl font-bold p-4">Guild Members</h2>
        <div className="p-4">
          <input
            type="text"
            placeholder="Filter members..."
            value={memberFilter}
            onChange={(e) => setMemberFilter(e.target.value)}
            className="w-full p-2 bg-gray-800 rounded"
          />
        </div>
        <div className="flex-grow overflow-y-auto">
          <GuildMembers
            guildMembers={guildMembers}
            segmentedMembers={segmentedMembers}
            toggleMemberInParty={toggleMemberInParty}
            selectedPartyId={selectedPartyId}
            isInAnyParty={isInAnyParty}
            memberFilter={memberFilter}
            setMemberFilter={setMemberFilter}
            generateParties={generateParties}
            generateAllParties={generateAllParties}
          />
        </div>
        <div className="p-4">
          <button
            onClick={() => generateParties(1)}
            className="mr-2 px-4 py-2 bg-blue-600 rounded"
          >
            Generate 1 Party
          </button>
          <button
            onClick={generateAllParties}
            className="px-4 py-2 bg-green-600 rounded"
          >
            Generate All Parties
          </button>
        </div>
      </div>
      <div className="w-full md:w-[70%] flex flex-col h-full">
        <h2 className="text-xl font-bold p-4">Parties</h2>
        <div className="flex-grow overflow-y-auto">
          <Parties
            parties={parties}
            selectedPartyId={selectedPartyId}
            setSelectedPartyId={setSelectedPartyId}
            createNewParty={createNewParty}
            calculateSynergy={calculateSynergy}
            deleteParty={deleteParty}
            startEditingPartyName={startEditingPartyName}
            updatePartyName={updatePartyName}
            editingPartyId={editingPartyId}
            editingPartyName={editingPartyName}
            setEditingPartyName={setEditingPartyName}
            togglePartyLeader={togglePartyLeader}
            toggleMemberInParty={toggleMemberInParty}
          />
        </div>
        <div className="p-4">
          <button
            onClick={createNewParty}
            className="px-4 py-2 bg-blue-600 rounded"
          >
            Add New Party
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartyGuild;