import React from 'react';

export interface GeneralSettingsFormProps {
  guildName: string;
  setGuildName: (value: string) => void;
  guildSlug: string;
  setGuildSlug: (value: string) => void;
  guildImage: string;
  setGuildImage: (value: string) => void;
  region: string;
  setRegion: (value: string) => void;
  server: string;
  setServer: (value: string) => void;
  discordLink: string;
  setDiscordLink: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
}

const regions = ['NA East', 'NA West', 'EU', 'Asia'];
const servers = ['Unknown', 'Server 1', 'Server 2', 'Server 3'];

const General: React.FC<GeneralSettingsFormProps> = ({
  guildName,
  setGuildName,
  guildSlug,
  setGuildSlug,
  guildImage,
  setGuildImage,
  region,
  setRegion,
  server,
  setServer,
  discordLink,
  setDiscordLink,
  description,
  setDescription,
}) => {
  return (
    <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-white">General Settings</h2>
      <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="guildName" className="block text-sm font-medium mb-1 text-gray-300">
            Guild name
          </label>
          <input
            id="guildName"
            type="text"
            value={guildName}
            onChange={(e) => setGuildName(e.target.value)}
            placeholder="Enter guild name"
            className="w-full p-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label htmlFor="guildSlug" className="block text-sm font-medium mb-1 text-gray-300">
            Guild slug
          </label>
          <input
            id="guildSlug"
            type="text"
            value={guildSlug}
            onChange={(e) => setGuildSlug(e.target.value)}
            placeholder="Ex: dragon-slayers"
            className="w-full p-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label htmlFor="guildImage" className="block text-sm font-medium mb-1 text-gray-300">
            Guild image
          </label>
          <input
            id="guildImage"
            type="text"
            value={guildImage}
            onChange={(e) => setGuildImage(e.target.value)}
            placeholder="Image URL"
            className="w-full p-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1 text-gray-300">
            Region
          </label>
          <div className="grid grid-cols-4 gap-1">
            {regions.map((r) => (
              <button
                key={r}
                type="button"
                onClick={() => setRegion(r)}
                className={`p-1 text-xs rounded ${
                  region === r ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                {r}
              </button>
            ))}
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium mb-1 text-gray-300">
            Server
          </label>
          <div className="grid grid-cols-4 gap-1">
            {servers.map((s) => (
              <button
                key={s}
                type="button"
                onClick={() => setServer(s)}
                className={`p-1 text-xs rounded ${
                  server === s ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                {s}
              </button>
            ))}
          </div>
        </div>
        <div>
          <label htmlFor="discordLink" className="block text-sm font-medium mb-1 text-gray-300">
            Discord link
          </label>
          <input
            id="discordLink"
            type="text"
            value={discordLink}
            onChange={(e) => setDiscordLink(e.target.value)}
            placeholder="Discord link"
            className="w-full p-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="md:col-span-2">
          <label htmlFor="description" className="block text-sm font-medium mb-1 text-gray-300">
            Guild description
          </label>
          <textarea
            id="description"
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            className="w-full p-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="md:col-span-2 flex space-x-2">
          <button
            type="submit"
            className="flex-1 p-2 rounded bg-blue-600 text-white font-bold hover:bg-blue-700"
          >
            Save Changes
          </button>
          <button
            type="button"
            className="flex-1 p-2 rounded bg-gray-600 text-white font-bold hover:bg-gray-700"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default General;