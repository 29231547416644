import React, { useState, useMemo } from 'react';

interface GuildMember {
  id: string;
  name: string;
  dkp: number;
  class: string;
}

interface Event {
  id: string;
  name: string;
  dkpReward: number;
  hasDrops: boolean;
}

interface Drop {
  id: string;
  name: string;
}

interface Participation {
  memberId: string;
  level: number;
}

const DKP: React.FC = () => {
  const events: Event[] = [
    { id: '1', name: 'Raid Semanal', dkpReward: 100, hasDrops: true },
    { id: '2', name: 'Evento PvP', dkpReward: 50, hasDrops: false },
    { id: '3', name: 'Dungeon Difícil', dkpReward: 75, hasDrops: true },
  ];

  const drops: Drop[] = [
    { id: '1', name: 'Espada Legendaria' },
    { id: '2', name: 'Armadura Épica' },
    { id: '3', name: 'Poción de Vida' },
  ];

  const guildMembers: GuildMember[] = useMemo(() => {
    return Array.from({ length: 50 }, (_, i) => ({
      id: `${i + 1}`,
      name: `Jugador ${i + 1}`,
      dkp: Math.floor(Math.random() * 1000),
      class: ['Guerrero', 'Mago', 'Sacerdote', 'Pícaro', 'Druida'][Math.floor(Math.random() * 5)]
    }));
  }, []);

  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [attendees, setAttendees] = useState<string[]>([]);
  const [selectedDrops, setSelectedDrops] = useState<string[]>([]);
  const [participationLevels, setParticipationLevels] = useState<Participation[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [hoveredMember, setHoveredMember] = useState<string | null>(null);

  const handleEventChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedEvent(e.target.value);
    setSelectedDrops([]);
    setParticipationLevels([]);
  };

  const handleDropToggle = (dropId: string) => {
    setSelectedDrops(prev => 
      prev.includes(dropId)
        ? prev.filter(id => id !== dropId)
        : [...prev, dropId]
    );
  };

  const handleParticipationChange = (memberId: string, level: number) => {
    setParticipationLevels(prev => {
      const existing = prev.find(p => p.memberId === memberId);
      if (existing) {
        return prev.map(p => p.memberId === memberId ? { ...p, level } : p);
      } else {
        return [...prev, { memberId, level }];
      }
    });
    // Añadir el miembro a la lista de asistentes si no está ya
    if (!attendees.includes(memberId)) {
      setAttendees(prev => [...prev, memberId]);
    }
  };

  const handleSubmit = () => {
    const event = events.find(e => e.id === selectedEvent);
    if (!event) {
      alert('Por favor, selecciona un evento.');
      return;
    }
    if (attendees.length === 0) {
      alert('Por favor, selecciona al menos un asistente.');
      return;
    }
    
    setShowModal(true);
  };

  const handleCloseEvent = () => {
    console.log('Evento cerrado y DKP actualizados');
    setShowModal(false);
    setSelectedEvent('');
    setAttendees([]);
    setSelectedDrops([]);
    setParticipationLevels([]);
  };

  const getParticipationColor = (level: number) => {
    if (level >= 1 && level <= 3) {
      return 'bg-red-600';
    } else if (level >= 4 && level <= 7) {
      return 'bg-yellow-500';
    } else if (level >= 8 && level <= 10) {
      return 'bg-green-600';
    }
    return 'bg-gray-700';
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-[1920px] mx-auto flex flex-col">
        <div className="flex flex-col md:flex-row">
          <div className="bg-gray-800 rounded-lg shadow-xl p-6 flex-grow md:mr-2 mb-4 md:mb-0">
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-4">Seleccionar Evento</h2>
              <div className="flex flex-col md:flex-row md:items-end md:space-x-4">
                <div className="flex-grow mb-4 md:mb-0">
                  <select 
                    value={selectedEvent} 
                    onChange={handleEventChange}
                    className="w-full p-2 bg-gray-700 rounded"
                  >
                    <option value="">Selecciona un evento</option>
                    {events.map(event => (
                      <option key={event.id} value={event.id}>{event.name} (+{event.dkpReward} DKP)</option>
                    ))}
                  </select>
                </div>
                <button 
                  onClick={handleSubmit}
                  className="w-full md:w-auto bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  Registrar Asistencia y Otorgar DKP
                </button>
              </div>
            </div>

            <h2 className="text-2xl font-bold mb-4">Miembros de la Guild</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4">
              {guildMembers.map(member => {
                const participationLevel = participationLevels.find(p => p.memberId === member.id)?.level;
                const colorClass = participationLevel ? getParticipationColor(participationLevel) : '';
                
                return (
                  <div 
                    key={member.id} 
                    className={`p-3 rounded-lg cursor-pointer transition-colors duration-200 h-24 flex flex-col justify-between text-white ${
                      attendees.includes(member.id) 
                        ? colorClass || 'bg-blue-600 hover:bg-blue-700'
                        : 'bg-gray-700 hover:bg-gray-600'
                    }`}
                    onMouseEnter={() => setHoveredMember(member.id)}
                    onMouseLeave={() => setHoveredMember(null)}
                  >
                    {hoveredMember === member.id ? (
                      <>
                        <p className="text-xs mb-1">Nota Participación:</p>
                        <div className="grid grid-cols-5 gap-1">
                          {Array.from({ length: 10 }, (_, i) => i + 1).map(level => (
                            <button
                              key={level}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleParticipationChange(member.id, level);
                              }}
                              className={`p-1 rounded text-xs font-bold transition-colors duration-200 text-white ${getParticipationColor(level)}`}
                            >
                              {level}
                            </button>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <h3 className="font-semibold truncate">{member.name}</h3>
                        </div>
                        <div>
                          <p className="text-sm">DKP: {member.dkp}</p>
                          {participationLevel && (
                            <p className="text-sm font-bold">
                              Nota: {participationLevel}
                            </p>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {events.find(e => e.id === selectedEvent)?.hasDrops && (
            <div className="bg-gray-800 rounded-lg shadow-xl p-3 md:w-48 flex-shrink-0">
              <h2 className="text-lg font-bold mb-2">Drops</h2>
              <div className="grid grid-cols-1 gap-1">
                {drops.map(drop => (
                  <div 
                    key={drop.id} 
                    className={`p-1 rounded-lg cursor-pointer transition-colors duration-200 ${
                      selectedDrops.includes(drop.id) 
                        ? 'bg-green-600 hover:bg-green-700' 
                        : 'bg-gray-700 hover:bg-gray-600'
                    }`}
                    onClick={() => handleDropToggle(drop.id)}
                  >
                    <h3 className="font-semibold truncate text-xs">{drop.name}</h3>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-md w-full">
              <h2 className="text-2xl font-bold mb-6 text-center">Resumen del Evento</h2>
              <div className="space-y-4 mb-6">
                <div className="flex flex-col items-center">
                  <p className="text-lg mb-2">Número de asistentes:</p>
                  <span className="text-3xl font-bold bg-green-600 text-white rounded-full w-16 h-16 flex items-center justify-center">
                    {attendees.length}
                  </span>
                </div>
                <div className="text-center">
                  <p className="text-lg mb-2">Evento:</p>
                  <p className="text-xl font-semibold">
                    {events.find(e => e.id === selectedEvent)?.name}
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-lg mb-2">DKP a otorgar:</p>
                  <span className="text-3xl font-bold bg-green-600 text-white rounded-full px-4 py-2 min-w-[4rem] flex items-center justify-center">
                    {events.find(e => e.id === selectedEvent)?.dkpReward}
                  </span>
                </div>
                {events.find(e => e.id === selectedEvent)?.hasDrops && (
                  <div className="text-center">
                    <p className="text-lg mb-2">Drops:</p>
                    <div className="flex flex-wrap justify-center">
                      {selectedDrops.map(dropId => (
                        <span key={dropId} className="bg-gray-700 text-white rounded-full px-4 py-2 m-1">
                          {drops.find(drop => drop.id === dropId)?.name}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={() => setShowModal(false)}
                  className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded transition duration-300"
                >
                  Volver
                </button>
                <button
                  onClick={handleCloseEvent}
                  className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded transition duration-300"
                >
                  Cerrar Evento
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DKP;
