import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { MapContainer, ImageOverlay, Marker, Popup, useMapEvents, useMap, FeatureGroup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { FaInfoCircle, FaHatWizard, FaShieldAlt, FaTimes, FaPencilAlt, FaEraser } from 'react-icons/fa';
import { GiCrossedSwords } from 'react-icons/gi';

interface Pin {
  id: number;
  x: number;
  y: number;
  party: string;
  map: string;
  type: 'info' | 'dps' | 'healer' | 'tank';
  description: string;
}

interface MapInfo {
  file: string;
  name: string;
}

interface DrawCreatedEvent extends L.LeafletEvent {
  layer: L.Layer;
  layerType: string;
}

const initialMaps: MapInfo[] = [
  { file: '/maps/zona2_asedio.PNG', name: 'Zona 2' },
  { file: '/maps/zona3_asedio.PNG', name: 'Zona 3' },
  { file: '/maps/zona4_asedio.PNG', name: 'Zona 4' },
];

const getRandomColor = () => {
          const contrastColors = [
                    '#FF1493', // Deep Pink
                    '#00FF00', // Lime Green
                    '#FFD700', // Gold
                    '#FF4500', // Orange Red
                    '#00FFFF', // Cyan
                    '#FF00FF', // Magenta
                    '#32CD32', // Lime Green
                    '#8A2BE2', // Blue Violet
                    '#FFA500', // Orange
                    '#FF6347', // Tomato
                    '#00FA9A', // Medium Spring Green
                    '#FF69B4', // Hot Pink
                    '#1E90FF', // Dodger Blue (cambiado de posición)
                    '#ADFF2F', // Green Yellow
                    '#DA70D6'  // Orchid
                  ];
                  let color;
                  do {
                    color = contrastColors[Math.floor(Math.random() * contrastColors.length)];
                  } while (color === '#1E90FF' || color === '#3699ff');
                  return color;
};

const GlobalStyle = createGlobalStyle`
  .leaflet-container {
    background: #111827 !important;
  }
`;

const Strategy: React.FC = () => {
  const [selectedMap, setSelectedMap] = useState<string>(initialMaps[0].file);
  const [pins, setPins] = useState<Pin[]>([]);
  const [nextPinId, setNextPinId] = useState(1);
  const [mapBounds, setMapBounds] = useState<L.LatLngBoundsExpression>([[0, 0], [1000, 1000]]);
  const [selectedParty, setSelectedParty] = useState<string>('Party 1');
  const [parties, setParties] = useState(['Party 1', 'Party 2', 'Party 3', 'Party 4']);
  const [partyColors, setPartyColors] = useState<Record<string, string>>(
    Object.fromEntries(parties.map(party => [party, getRandomColor()]))
  );
  const mapRef = useRef<L.Map | null>(null);
  const [maps, setMaps] = useState<MapInfo[]>(initialMaps);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newZoneFile, setNewZoneFile] = useState<File | null>(null);
  const [newZoneName, setNewZoneName] = useState('');
  const [editingParty, setEditingParty] = useState<string | null>(null);
  const [selectedPinType, setSelectedPinType] = useState<Pin['type'] | null>(null);
  const [selectedPinId, setSelectedPinId] = useState<number | null>(null);
  const [drawingMode, setDrawingMode] = useState(false);
  const [drawnItems, setDrawnItems] = useState<L.FeatureGroup | null>(null);
  const [drawControl, setDrawControl] = useState<L.Control.Draw | null>(null);

  

  useEffect(() => {
    if (mapRef.current && drawnItems) {
      mapRef.current.addLayer(drawnItems);
      
      const handleCreated = (e: DrawCreatedEvent) => {
        const layer = e.layer;
        drawnItems.addLayer(layer);
        saveDrawnItems();
      };
    
      mapRef.current.on(L.Draw.Event.CREATED, handleCreated as L.LeafletEventHandlerFn);
      
      return () => {
        mapRef.current?.off(L.Draw.Event.CREATED, handleCreated as L.LeafletEventHandlerFn);
      };
    }
  }, [drawnItems]);

  useEffect(() => {
    if (mapRef.current) {
      const newDrawnItems = new L.FeatureGroup();
      mapRef.current.addLayer(newDrawnItems);
      setDrawnItems(newDrawnItems);

      const drawControlOptions: L.Control.DrawConstructorOptions = {
        draw: {
          polyline: {
            shapeOptions: {
              color: '#3388ff',
              weight: 4
            }
          },
          polygon: false,
          circle: false,
          rectangle: false,
          marker: false,
          circlemarker: false
        },
        edit: {
          featureGroup: newDrawnItems,
          remove: true
        }
      };

      const newDrawControl = new L.Control.Draw(drawControlOptions);
      setDrawControl(newDrawControl);

      if (drawingMode) {
        mapRef.current.addControl(newDrawControl);
      }

      loadDrawnItems(newDrawnItems);

      return () => {
        if (mapRef.current) {
          mapRef.current.removeLayer(newDrawnItems);
          if (drawControl) {
            mapRef.current.removeControl(drawControl);
          }
        }
      };
    }
  }, [drawingMode, selectedMap]);

  const clearDrawnItems = () => {
          localStorage.removeItem(`drawnItems_${selectedMap}`);
          if (drawnItems) {
            drawnItems.clearLayers();
          }
          setDrawnItems(new L.FeatureGroup());
        };

  const saveDrawnItems = () => {
    if (drawnItems) {
      const drawnItemsData = drawnItems.toGeoJSON();
      localStorage.setItem(`drawnItems_${selectedMap}`, JSON.stringify(drawnItemsData));
    }
  };

  const loadDrawnItems = (featureGroup: L.FeatureGroup) => {
    const savedItems = localStorage.getItem(`drawnItems_${selectedMap}`);
    if (savedItems) {
      const geoJSONLayer = L.geoJSON(JSON.parse(savedItems));
      geoJSONLayer.eachLayer((layer) => {
        featureGroup.addLayer(layer);
      });
    }
  };

  const handleMapClick = (e: L.LeafletMouseEvent) => {
    if (selectedPinType && !drawingMode) {
      const newPin: Pin = {
        id: nextPinId,
        x: e.latlng.lat,
        y: e.latlng.lng,
        party: selectedParty,
        map: selectedMap,
        type: selectedPinType,
        description: '',
      };
      setPins([...pins, newPin]);
      setNextPinId(nextPinId + 1);
    }
  };

  const addNewParty = () => {
    const newParty = `Party ${parties.length + 1}`;
    setParties([...parties, newParty]);
    setPartyColors({...partyColors, [newParty]: getRandomColor()});
    setSelectedParty(newParty);
  };

  const updatePartyName = (oldName: string, newName: string) => {
    setParties(parties.map(p => p === oldName ? newName : p));
    setPartyColors({...partyColors, [newName]: partyColors[oldName]});
    delete partyColors[oldName];
    setPins(pins.map(pin => pin.party === oldName ? {...pin, party: newName} : pin));
    setEditingParty(null);
  };

  const createColoredIcon = useCallback((color: string, type: Pin['type'], isSelected: boolean) => {
    let IconComponent;
    switch (type) {
      case 'dps':
        IconComponent = GiCrossedSwords;
        break;
      case 'healer':
        IconComponent = FaHatWizard;
        break;
      case 'tank':
        IconComponent = FaShieldAlt;
        break;
      default:
        IconComponent = FaInfoCircle;
    }
    return L.divIcon({
      className: 'custom-pin',
      html: `<div style="color: ${color}; width: 24px; height: 24px; filter: drop-shadow(0px 0px 1px #000000); ${isSelected ? 'transform: scale(1.5);' : ''}">
               ${renderToString(<IconComponent />)}
             </div>`,
      iconSize: [24, 24],
      iconAnchor: [12, 12],
      popupAnchor: [0, -12],
    });
  }, []);

  const MapEvents = () => {
    const map = useMap();
    useMapEvents({
      click: handleMapClick,
    });
    mapRef.current = map;

    useEffect(() => {
      if (selectedPinType && !drawingMode) {
        map.getContainer().style.cursor = 'crosshair';
      } else {
        map.getContainer().style.cursor = '';
      }
    }, [selectedPinType, drawingMode, map]);

    return null;
  };

  const handleNewZoneUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setNewZoneFile(file);
      setIsModalOpen(true);
    }
  };

  const handleModalSubmit = () => {
    if (newZoneFile && newZoneName) {
      const newMap: MapInfo = {
        file: URL.createObjectURL(newZoneFile),
        name: newZoneName
      };
      setMaps([...maps, newMap]);
      setSelectedMap(newMap.file);
      setIsModalOpen(false);
      setNewZoneFile(null);
      setNewZoneName('');
    }
  };

  const updatePin = useCallback((updatedPin: Pin) => {
    setPins(prevPins => prevPins.map(pin => 
      pin.id === updatedPin.id ? { ...pin, ...updatedPin } : pin
    ));
  }, []);

  const deletePin = useCallback((pinId: number) => {
    setPins(prevPins => prevPins.filter(pin => pin.id !== pinId));
  }, []);

  const toggleDrawingMode = () => {
    setDrawingMode(prev => !prev);
    setSelectedPinType(null);
    if (mapRef.current && drawControl) {
      if (!drawingMode) {
        mapRef.current.addControl(drawControl);
      } else {
        mapRef.current.removeControl(drawControl);
      }
    }
  };

  const PinToolbar = () => {
    return (
      <PinToolbarContainer>
        <PinToolbarButton 
          onClick={() => { setSelectedPinType('info'); setDrawingMode(false); }}
          isSelected={selectedPinType === 'info' && !drawingMode}
        >
          <FaInfoCircle /> Info
        </PinToolbarButton>
        <PinToolbarButton 
          onClick={() => { setSelectedPinType('dps'); setDrawingMode(false); }}
          isSelected={selectedPinType === 'dps' && !drawingMode}
        >
          <GiCrossedSwords /> DPS
        </PinToolbarButton>
        <PinToolbarButton 
          onClick={() => { setSelectedPinType('healer'); setDrawingMode(false); }}
          isSelected={selectedPinType === 'healer' && !drawingMode}
        >
          <FaHatWizard /> Healer
        </PinToolbarButton>
        <PinToolbarButton 
          onClick={() => { setSelectedPinType('tank'); setDrawingMode(false); }}
          isSelected={selectedPinType === 'tank' && !drawingMode}
        >
          <FaShieldAlt /> Tank
        </PinToolbarButton>
        <PinToolbarButton 
          onClick={toggleDrawingMode}
          isSelected={drawingMode}
        >
          <FaPencilAlt /> Dibujar
        </PinToolbarButton>
        <PinToolbarButton 
    onClick={clearDrawnItems}
    isSelected={false}
  >
    <FaEraser /> Limpiar Líneas
  </PinToolbarButton>
      </PinToolbarContainer>
    );
  };

  const PartyPinGrid: React.FC<{ pins: Pin[] }> = ({ pins }) => {
    return (
      <PinGrid>
        {pins.map((pin) => (
          <PinItem key={pin.id}>
            {pin.type === 'info' && <FaInfoCircle />}
            {pin.type === 'dps' && <GiCrossedSwords />}
            {pin.type === 'healer' && <FaHatWizard />}
            {pin.type === 'tank' && <FaShieldAlt />}
          </PinItem>
        ))}
      </PinGrid>
    );
  };

  const EditablePinGrid = React.memo<{ 
    pins: Pin[], 
    updatePin: (pin: Pin) => void, 
    deletePin: (pinId: number) => void,
    setSelectedPinId: (id: number | null) => void
  }>(({ pins, updatePin, deletePin, setSelectedPinId }) => {
    const inputRefs = useRef<{[key: number]: HTMLInputElement | null}>({});

    const handleBlur = useCallback((pin: Pin) => {
      const value = inputRefs.current[pin.id]?.value || '';
      if (value !== pin.description) {
        updatePin({ ...pin, description: value });
      }
      setSelectedPinId(null);
    }, [updatePin, setSelectedPinId]);

    const handleFocus = useCallback((pinId: number) => {
      setSelectedPinId(pinId);
    }, [setSelectedPinId]);

    return (
      <PinGridContainer>
        {pins.map((pin) => (
          <PinGridItem key={pin.id} isSelected={pin.id === selectedPinId}>
            <PinIcon>
              {pin.type === 'info' && <FaInfoCircle />}
              {pin.type === 'dps' && <GiCrossedSwords />}
              {pin.type === 'healer' && <FaHatWizard />}
              {pin.type === 'tank' && <FaShieldAlt />}
            </PinIcon>
            <PinDescription
              ref={el => inputRefs.current[pin.id] = el}
              defaultValue={pin.description}
              onBlur={() => handleBlur(pin)}
              onFocus={() => handleFocus(pin.id)}
              placeholder="Añadir descripción (opcional)..."
            />
            <DeleteButton onClick={() => deletePin(pin.id)}>
              <FaTimes />
            </DeleteButton>
          </PinGridItem>
        ))}
      </PinGridContainer>
    );
  });

  const MapContent = () => {
          const map = useMap();
          const featureGroupRef = useRef<L.FeatureGroup | null>(null);
        
          useEffect(() => {
            mapRef.current = map;
          }, [map]);
        
          useEffect(() => {
            if (featureGroupRef.current && drawnItems) {
              featureGroupRef.current.clearLayers();
              drawnItems.eachLayer((layer) => {
                featureGroupRef.current?.addLayer(layer);
              });
            }
          }, [drawnItems]);
  
    return (
      <>
        <ImageOverlay
          url={selectedMap}
          bounds={mapBounds}
        />
{pins.filter(pin => pin.map === selectedMap).map((pin) => (
        <Marker 
          key={pin.id} 
          position={[pin.x, pin.y]} 
          icon={createColoredIcon(partyColors[pin.party], pin.type, pin.id === selectedPinId)}
        >
          <Popup>{`${pin.party} - ${pin.type}`}</Popup>
        </Marker>
      ))}
      <FeatureGroup ref={featureGroupRef} />
      </>
    );
  };

  return (
    <>
      <GlobalStyle />
      <StrategyContainer>
        <LeftPanel>
          <ZoneGrid>
            {maps.map((map) => (
              <ZoneCard 
                key={map.file}
                isSelected={selectedMap === map.file}
                onClick={() => setSelectedMap(map.file)}
              >
                {map.name}
              </ZoneCard>
            ))}
            <AddZoneCard>
              <label htmlFor="newZoneUpload">
                <PlusIcon>+</PlusIcon>
                <span>Nueva Zona</span>
              </label>
              <input
                id="newZoneUpload"
                type="file"
                accept=".png,.jpg,.jpeg"
                style={{ display: 'none' }}
                onChange={handleNewZoneUpload}
              />
            </AddZoneCard>
          </ZoneGrid>
          <CurrentZoneCard>
            <h3>{maps.find(map => map.file === selectedMap)?.name}</h3>
            <img src={selectedMap} alt="Current Zone" style={{ width: '100%', height: 'auto' }} />
          </CurrentZoneCard>
        </LeftPanel>
        <CenterPanel>
          <PinToolbar />
          <MapWrapper>
            <MapContainer
              bounds={mapBounds}
              style={{ height: '500px', width: '100%', backgroundColor: '#111827' }}
              crs={L.CRS.Simple}
              zoomControl={false}
              minZoom={-2}
              attributionControl={false}
            >
              <MapEvents />
              <MapContent />
            </MapContainer>
          </MapWrapper>
          <PartyGrid>
            {parties.map((party) => (
              <PartyCard 
                key={party} 
                color={partyColors[party]}
                isSelected={selectedParty === party}
                onClick={() => setSelectedParty(party)}
              >
                {editingParty === party ? (
                  <input
                    value={party}
                    onChange={(e) => updatePartyName(party, e.target.value)}
                    onBlur={() => setEditingParty(null)}
                    autoFocus
                  />
                ) : (
                  <h4 onDoubleClick={() => setEditingParty(party)}>{party}</h4>
                )}
                <PartyPinGrid pins={pins.filter(pin => pin.party === party && pin.map === selectedMap)} />
              </PartyCard>
            ))}
            <AddPartyCard onClick={addNewParty}>
              <PlusIcon>+</PlusIcon>
              <span>New Party</span>
            </AddPartyCard>
          </PartyGrid>
        </CenterPanel>
        <RightPanel>
          <h3>Descripciones de Pines</h3>
          <EditablePinGrid 
            pins={pins.filter(pin => pin.map === selectedMap && pin.party === selectedParty)} 
            updatePin={updatePin} 
            deletePin={deletePin}
            setSelectedPinId={setSelectedPinId}
          />
        </RightPanel>
        {isModalOpen && (
          <Modal>
            <ModalContent>
              <h2>Nueva Zona</h2>
              <input
                type="text"
                value={newZoneName}
                onChange={(e) => setNewZoneName(e.target.value)}
                placeholder="Nombre de la zona"
              />
              <ModalButtons>
                <button onClick={() => setIsModalOpen(false)}>Cancelar</button>
                <button onClick={handleModalSubmit}>Guardar</button>
              </ModalButtons>
            </ModalContent>
          </Modal>
        )}
      </StrategyContainer>
    </>
  );
};

const StrategyContainer = styled.div`
  display: flex;
  background-color: #111827;
  color: #ffffff;
  height: 100vh;
`;

const LeftPanel = styled.div`
  width: 200px;
  padding: 20px;
  background-color: #111827;
  display: flex;
  flex-direction: column;
`;

const CenterPanel = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const RightPanel = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #1f2937;
  overflow-y: auto;
`;

const ZoneGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
`;

const ZoneCard = styled.div<{ isSelected: boolean }>`
  background-color: ${props => props.isSelected ? '#3699ff' : '#1f2937'};
  color: white;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.isSelected ? '#3699ff' : '#2d3748'};
  }
`;

const AddZoneCard = styled.div`
  background-color: #1f2937;
  color: white;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  grid-column: span 2;

  &:hover {
    background-color: #2d3748;
  }
`;

const PlusIcon = styled.span`
  font-size: 24px;
  margin-bottom: 5px;
`;

const CurrentZoneCard = styled.div`
  background-color: #1f2937;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;

  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const MapWrapper = styled.div`
  border: 2px solid #3699ff;
  border-radius: 4px;
  overflow: hidden;
`;

const PartyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 20px;
`;

const PartyCard = styled.div<{ color: string; isSelected: boolean }>`
  background-color: ${props => props.isSelected ? '#3699ff' : '#1f2937'};
  color: white;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.isSelected ? '#3699ff' : '#2d3748'};
  }

  h4 {
    margin: 0 0 10px 0;
    color: ${props => props.color};
  }

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    font-size: 16px;
    padding: 5px 0;
    margin-bottom: 10px;

    &:focus {
      outline: none;
    }
  }
`;

const AddPartyCard = styled.div`
  background-color: #1f2937;
  color: white;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2d3748;
  }
`;

const PinGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const PinItem = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 25px;
  height: 25px;
`;

const PinToolbarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const PinToolbarButton = styled.button<{ isSelected: boolean }>`
  background-color: ${props => props.isSelected ? '#3699ff' : '#1f2937'};
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.isSelected ? '#3699ff' : '#2d3748'};
  }

  svg {
    margin-right: 5px;
  }

  ${props => props.isSelected && `
    box-shadow: 0 0 0 2px white;
    font-weight: bold;
  `}
`;

const PinGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin-top: 20px;
`;

const PinGridItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${props => props.isSelected ? '#2d3748' : '#1f2937'};
  border-radius: 4px;
  padding: 10px;
  transition: background-color 0.3s ease;
`;

const PinIcon = styled.div`
  font-size: 20px;
  margin-right: 10px;
`;

const PinDescription = styled.input`
  flex: 1;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 14px;
  transition: background-color 0.3s ease;

  &:focus {
    outline: none;
    background-color: rgba(54, 153, 255, 0.2);
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #ff4d4f;
  cursor: pointer;
  padding: 5px;
  margin-left: 5px;
  
  &:hover {
    color: #ff7875;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #1f2937;
  padding: 20px;
  border-radius: 8px;
  width: 300px;

  h2 {
    margin-top: 0;
    margin-bottom: 15px;
  }

  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #4b5563;
    background-color: #374151;
    color: white;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    padding: 8px 16px;
    margin-left: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;

    &:first-child {
      background-color: #4b5563;
      color: white;
    }

    &:last-child {
      background-color: #3699ff;
      color: white;
    }
  }
`;

export default Strategy;