import React from 'react';
import weaponCombinations from '../data/rolesData';

interface JoinRequest {
  id: number;
  playerName: string;
  weapons: string[];
  requestDate: Date;
}

const GuildJoinRequests: React.FC = () => {
  const [pendingRequests, setPendingRequests] = React.useState<JoinRequest[]>([
    { id: 1, playerName: 'Player1', weapons: ['Greatsword', 'Staff'], requestDate: new Date() },
    { id: 2, playerName: 'Player2', weapons: ['sns', 'Wand'], requestDate: new Date() },
    { id: 3, playerName: 'Player3', weapons: ['Bow', 'Dagger'], requestDate: new Date() },
    { id: 4, playerName: 'Player4', weapons: ['wand', 'bow'], requestDate: new Date() },
    { id: 5, playerName: 'Player5', weapons: ['staff', 'bow'], requestDate: new Date() },
  ]);
  const [approvedRequests, setApprovedRequests] = React.useState<JoinRequest[]>([]);
  const [rejectedRequests, setRejectedRequests] = React.useState<JoinRequest[]>([]);

  const mapWeaponName = (weapon: string): string => {
    const weaponMap: { [key: string]: string } = {
      'crossbow': 'Bow',
      'staff': 'Staff',
      'sns': 'Sword and Shield',
      'greatsword': 'Greatsword',
      'dagger': 'Dagger',
      'bow': 'Bow',
      'wand': 'Wand'
    };
    return weaponMap[weapon.toLowerCase()] || weapon;
  };

  const getRole = (weapons: string[]): string => {
    const mappedWeapons = weapons.map(mapWeaponName);
    const combination = weaponCombinations.find(
      (combo) => combo.weapons.sort().join(',') === mappedWeapons.sort().join(',')
    );
    return combination ? combination.role : 'Unknown';
  };

  const handleApprove = (request: JoinRequest) => {
    setPendingRequests(pendingRequests.filter(r => r.id !== request.id));
    setApprovedRequests([...approvedRequests, request]);
  };

  const handleDeny = (request: JoinRequest) => {
    setPendingRequests(pendingRequests.filter(r => r.id !== request.id));
    setRejectedRequests([...rejectedRequests, request]);
  };

  const RequestCard: React.FC<{ request: JoinRequest; onApprove?: () => void; onDeny?: () => void }> = ({ request, onApprove, onDeny }) => (
    <div key={request.id} className="bg-gray-700 rounded-lg p-3 mb-2 text-white text-sm">
      <div className="flex justify-between items-center mb-1">
        <h4 className="font-bold">{request.playerName}</h4>
        <span className="text-xs">{request.requestDate.toLocaleDateString()}</span>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <p><span className="font-semibold">Rol:</span> {getRole(request.weapons)}</p>
          <div className="flex items-center">
            <span className="font-semibold mr-1">Armas:</span>
            {request.weapons.map((weapon) => (
              <img 
                key={weapon} 
                src={`/icon/${weapon.toLowerCase().replace(/ /g, '_')}.png`} 
                alt={weapon}
                className="w-4 h-4 mr-1"
              />
            ))}
          </div>
        </div>
        {onApprove && onDeny && (
          <div className="flex space-x-1">
            <button onClick={onApprove} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded text-xs">
              Aprobar
            </button>
            <button onClick={onDeny} className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded text-xs">
              Rechazar
            </button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="bg-gray-900 min-h-screen p-4">
      <h2 className="text-white text-2xl font-bold mb-6">Solicitudes de unión a la guild</h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="bg-gray-800 rounded-lg p-4">
          <h3 className="text-white text-lg font-bold mb-3">Solicitudes Pendientes ({pendingRequests.length})</h3>
          {pendingRequests.map((request) => (
            <RequestCard 
              key={request.id} 
              request={request} 
              onApprove={() => handleApprove(request)} 
              onDeny={() => handleDeny(request)} 
            />
          ))}
        </div>
        <div className="bg-gray-800 rounded-lg p-4">
          <h3 className="text-white text-lg font-bold mb-3">Solicitudes Aprobadas ({approvedRequests.length})</h3>
          {approvedRequests.map((request) => (
            <RequestCard key={request.id} request={request} />
          ))}
        </div>
        <div className="bg-gray-800 rounded-lg p-4">
          <h3 className="text-white text-lg font-bold mb-3">Solicitudes Rechazadas ({rejectedRequests.length})</h3>
          {rejectedRequests.map((request) => (
            <RequestCard key={request.id} request={request} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuildJoinRequests;